import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../../../services/authentication.service';
import { environment } from '../../../environments/environment';

// import { AutologoutService } from 'src/services/member/autologout.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    currentTwofaToken: any;
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = this.authenticationService.currentUserValue;
        if (currentUser && (currentUser.token || currentUser['Two-FA-Token']) && (currentUser.header)) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'JWT ' + (currentUser.token ? currentUser.token : currentUser['Two-FA-Token'])
                }
            });

            if(request.url.includes('tenants/basic-info/') || request.url.includes('systemusers/reset-password/') || request.url.includes('accounts/do-reset-password/')) {
                request = request.clone({
                    headers: new HttpHeaders({
                    })
                });
            }

            if(request.url == environment.contentful.url + environment.contentful.spaceId + '/environments/' + environment.contentful.environments) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${environment.contentful.token}`
                    }
                });
            } 

            if(request.url.includes('content/v1/spaces')) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${environment.contentful.token}`
                    }
                });
            } 

            if(request.url.includes('generate-email-otp/') || request.url.includes('verify-email-otp/') || request.url.includes('generate-2fa-qr/') || request.url.includes('setup-2fa/') || request.url.includes('verify-totp/')){
                this.currentTwofaToken = localStorage.getItem('for_2fa_only')
                request = request.clone({
                    headers: new HttpHeaders({
                        'Two-FA-Token': this. currentTwofaToken
                    })
                });
            }
        }
        else {
            let setHeaders: any = {}
            request = request.clone({ setHeaders: setHeaders });
        }
        return next.handle(request);
    }
}