<div class="header-container">
    <div class="header-content">
        <div class="header-left-content">
            <img class="sponsor-image" [src]='sponsorLogo' alt="sponsor-logo" (click)="routeToLogin()">
            <div class="sponsor-header">{{headerFooterCMSData?.sponsorName}}</div>
            <div class="powered-header">{{headerFooterCMSData?.poweredByLabel}}</div>
            <div class="gravty-header">{{headerFooterCMSData?.gravtyLabel}}</div>
        </div>
        <div class="header-right-content" *ngIf="isLogin" >
            <span *ngIf="getGenericSettings?.showHeaderNotificationIcon"><img class="bell-icon" [src]='notificationIcon' alt="bell-icon"></span>
            <span *ngIf="getGenericSettings?.showHeaderSettingsIcon"><img class="settings-icon" [src]='settingsIcon' alt="settings-icon"></span>
            <div class="header-right-content__profile"  (click)="logout()">
            <div class="header--profile">
              <img class="header--profile__image" [src]="logoutIcon" alt="" id="user">
           </div>
            <!-- <div class="header-right-content__profile__logout-dropdown" [hidden]="!showTooltip">
                    <ul
                      class="logout-menu"
                    >
                      <li class="logout-menu-item"
                      tabindex="0" 
                      role="menuitem"
                      (keyup.enter)="logout()"
                      (click)="logout()"
                      >
                        <a
                          >Logout</a
                        >
                      </li>
                    </ul>
            </div>  -->
            </div>
        </div>
    </div>
</div>
