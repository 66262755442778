<div class="registartion-success-container">
    <div class="registartion-success-content">
        <div class="registartion-success-header"[ngStyle]="{'background-image': 'url(' + backgroundImg + ')'}">
            <img class="logo" [src]="'https:' + sponsorOnboardingCMSData?.onboardingSponsorLogo.fields.file.url" alt="partner-logo">
        </div>

        <div class="registartion-success-msg">
            <div class="sucess-header">{{popupTitle}}</div>
            <div class="sucess-desc">
                <ngx-contentful-rich-text [document]="popupDesc"></ngx-contentful-rich-text>
            </div>
            <div class="button-container">
                <app-button [displayText]="popupCloseCTA" type="secondary" [height]="48" [width]="155" (clicked)="submitDetails()"></app-button>
            </div>
        </div>
    </div>
</div>
