<div class="invoice-details" *ngIf="!isGlobalLoaderOn">
  <app-global-error *ngIf="error" [(apiError)]="errorMsg"></app-global-error>
  <div class="invoice-details__header">
    <div class="invoice-details__header__left">
      <h2 class="invoice-details__header__left__title">Invoice Details</h2>
      <p class="invoice-details__header__left__description">
        View invoice details and explore related transactions for comprehensive financial tracking.
      </p>
    </div>
    <div class="invoice-details__header__right">
      <app-button
        [width]="66"
        [height]="42"
        [showIcon]="true"
        [type]="'secondary'"
        [imageUrl]="'/assets/images/icons/secondary_download.jpg'"
        [showBorder]="true"
        imageIconWidth="13px"
        *ngIf="invoiceData"
        (clicked)="downloadInvoice()"
      ></app-button>
    </div>
  </div>
  <app-spacer [height]="30"></app-spacer>
  <div class="invoice-details__sponsor-details">
    <div class="invoice-details__sponsor-details__value">
      <span class="invoice-details__sponsor-details__value__label">Brand:</span>
      <span class="invoice-details__sponsor-details__value__value">{{
        sponsorDetails?.name
      }}</span>
    </div>
    <app-spacer [height]="20"></app-spacer>
    <div class="invoice-details__sponsor-details__value">
      <span class="invoice-details__sponsor-details__value__label"
        >Address:</span
      >
      <span class="invoice-details__sponsor-details__value__value">
        {{ sponsorDetails?.address }}</span
      >
    </div>
    <app-spacer [height]="20"></app-spacer>
  </div>
  <div class="invoice-details__hr"></div>
  <app-spacer [height]="20"></app-spacer>
  <div class="invoice-details__invoice-description" *ngIf="invoiceData">
    <div class="invoice-details__invoice-description__left">
      <div class="invoice-details__sponsor-details__value">
        <span class="invoice-details__sponsor-details__value__label"
          >Invoice Period:</span
        >
        <span class="invoice-details__sponsor-details__value__value">{{
          invoiceData?.invoicing_period
        }}</span>
      </div>
      <app-spacer [height]="20"></app-spacer>
      <div class="invoice-details__sponsor-details__value">
        <span class="invoice-details__sponsor-details__value__label"
          >Issue Date:</span
        >
        <span class="invoice-details__sponsor-details__value__value">{{
          invoiceData?.issue_date
        }}</span>
      </div>
    </div>
    <div class="invoice-details__invoice-description__right">
      <div class="invoice-details__sponsor-details__value">
        <span class="invoice-details__sponsor-details__value__label"
          >Total Points Awarded:</span
        >
        <span class="invoice-details__sponsor-details__value__value">{{
          invoiceData?.total_points | addComma
        }}</span>
      </div>
      <app-spacer [height]="20"></app-spacer>
      <div class="invoice-details__sponsor-details__value">
        <span class="invoice-details__sponsor-details__value__label"
          >Invoice value:</span
        >
        <span class="invoice-details__sponsor-details__value__value"
          >{{ currencySymbol }} {{ invoiceData?.cost | addComma }}</span
        >
      </div>
    </div>
    <app-spacer [height]="20"></app-spacer>
  </div>
  <div class="invoice-details__hr"></div>
  <app-spacer [height]="20"></app-spacer>
  <div class="invoice-details__transactions">
    <div class="invoice-details__transactions__header">
      <div class="invoice-details__header__left__title">Transactions</div>
      <div class="invoice-details__transactions__header__download">
        <app-button
          [width]="66"
          [height]="42"
          [showIcon]="true"
          [imageUrl]="'/assets/images/icons/download.png'"
          [borderColor]="'rgba(201,211,225,0.65)'"
          [showBorder]="true"
          imageIconWidth="13px"
          (clicked)="showtooltip=!showtooltip"
          *ngIf="transactionDetails?.length"
        ></app-button>
        <div class="invoice-details__transactions__header__download__tooltip" *ngIf="showtooltip">
          <div
            class="invoice-details__transactions__header__download__tooltip--content"
          >
            <div
              class="invoice-details__transactions__header__download__tooltip__value mg-10"
              (click)="downloadTransactions('csv')"
            >
              Download as CSV
            </div>
            <div
              class="invoice-details__transactions__header__download__tooltip__value"
              (click)="downloadTransactions('xlsx')"
            >
              Download as XLS
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-spacer [height]="20"></app-spacer>
    <div class="invoice-details__transactions__body">
      <app-invoice-transactions-details
        *ngIf="!showloader && transactionDetails.length"
        [transactionDetails]="transactionDetails"
      ></app-invoice-transactions-details>
      <app-inline-loader
        *ngIf="showloader"
        [inlineLoaderText]="'Loading Transactions...'"
        [height]="'625px'"
      ></app-inline-loader>
      <app-placeholder
        [width]="100"
        [widthUnit]="'%'"
        *ngIf="transactionDetails?.length == 0 && !showloader"
        [helpText]="'No Invoice Transactions available'"
      ></app-placeholder>
      <app-spacer [height]="15" *ngIf="transactionDetails?.length"></app-spacer>
      <app-pagination
        *ngIf="transactionDetails?.length"
        [width]="100"
        [widthUnit]="'%'"
        [height]="18"
        [previousUrl]="previousPage"
        [nextUrl]="nextPage"
        (navigate)="handlePagination($event)"
      ></app-pagination>
      <app-spacer [height]="50"></app-spacer>
      <div class="invoice-details__transactions__body__footer">
        <app-button
          [displayText]="'Close'"
          [width]="250"
          [height]="48"
          [showBorder]="false"
          (clicked)="goToPaymentListing()"
        ></app-button>
      </div>
    </div>
  </div>
</div>
