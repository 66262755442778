import {
  Directive,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { SuperFilterComponent } from '../filters/super-filter/super-filter.component';
import { SuperFilterConfig } from '../interfaces/super-filter-config.interface';

@Directive({
  selector: '[appSuperFilter]',
})
export class SuperFilterDirective {
  @Input('config') superFilterConfig?: SuperFilterConfig;
  @Output() configChange: EventEmitter<SuperFilterConfig> =
    new EventEmitter<SuperFilterConfig>();
  constructor(private matDialog: MatDialog) {}
  @HostListener('click', ['$event']) openSuperFilter() {
    const dialogConfig: MatDialogConfig = {
      backdropClass: ['hidable-backdrop'],
      height: '85vh',
      position: {
        top: '85px',
        right: '16px',
      },
      closeOnNavigation: true,
      disableClose: true,
      ariaLabel: 'Super Filter',
      enterAnimationDuration: '0ms',
      data: this.superFilterConfig,
      panelClass: ['super-filter', 'hidable-panel'],
    };
    window.scrollTo(0, 0);
    const dialogRef = this.matDialog.open(SuperFilterComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((config: { data: SuperFilterConfig }) => {
      document.body.classList?.remove('cdk-global-scrollblock');
      if (config?.data) {
        this.configChange.emit(structuredClone(config.data));
      }
    });

    dialogRef.afterOpened().subscribe(() => {
      document.body.classList.add('cdk-global-scrollblock');
    });
  }
}
