import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, from, map, Observable, Subject } from 'rxjs';
import { GlobalLoaderComponent } from '../library/lib/components/global-loader/global-loader.component';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { environment } from '../../environments/environment';
import { dropdownData } from '../library/lib/data/dropdown.data';
@Injectable({
  providedIn: 'root',
})
export class SharedService {
  private _globalLoader: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  private _globalLoaderRef?: MatDialogRef<GlobalLoaderComponent>;
  private host=environment.host();
  constructor(private matDialog: MatDialog,private http:HttpClient) {
  }
  toggleGlobalLoader(show: boolean) {
    this._globalLoader.next(show);
    if (this._globalLoader.value && !this._globalLoaderRef) {
      this._globalLoaderRef = this.matDialog.open(GlobalLoaderComponent, {
        hasBackdrop: true,
        disableClose: true,
        closeOnNavigation: false,
        exitAnimationDuration: '100ms',
        enterAnimationDuration: '200ms',
      });
    } else if (this._globalLoaderRef && !this._globalLoader.value) {
      this._globalLoaderRef.close();
      this._globalLoaderRef = undefined;
    }
  }

   get currentUser(){
    return JSON.parse(
      localStorage.getItem('currentUser') ?? '[]'
    );;
   }
  ngOnDestroy(): void {
    if (this._globalLoader) {
      this._globalLoader.complete();
    }
    if (this._globalLoaderRef) {
      this._globalLoaderRef.close();
    }
  }
  get programInfo() {
    const formatedData = JSON.parse(
      localStorage.getItem('programInfo') ?? '[]'
    );
    return formatedData[0];
  }

  get programTimeZone(): string {
    return this.programInfo.timezone?? '';

  }
  get dateFormat(): string {
    return this.programInfo?.date_format ?? 'DD/MM/YYYY';
  }

  get dateTimeFormat(): string {
    return this.dateFormat + ' hh:mm A';
  }

  get getCurrencyCode(){
   return this.programInfo?.currency
  }

  get currentSponsorId() {
    return this.currentUser.sponsor_id;
  }
  get genericSettings(){
   return JSON.parse(
      localStorage.getItem('portalGenericSettings') ?? '[]'
    );
  }
  getCurrencySymbol():void {
    let currencySymbol = localStorage.getItem('currency_symbol') ?? '';
    if (currencySymbol?.length > 0) {
      return;
    }
    this.getCurrenciesData().subscribe({
      next:(data:any)=>{
        if (this.programInfo?.currency) {
          currencySymbol =
            data[0].countries.find(
              (currency: any) => this.programInfo?.currency == currency.code
            )?.symbol??
            this.programInfo?.currency??
            '';
          localStorage.setItem('currency_symbol', currencySymbol);
        }

      },
      error:(err)=>{
      }
   });
  }
  get currencySymbol(){
    return localStorage.getItem('currency_symbol')?? '';
  }
  get sponsorDetails(){
    return JSON.parse(localStorage.getItem('sponsorDetails')?? '[]');
  }
  getCurrenciesData() {
    return of(dropdownData);
  }
  getLocationByGoogleApi(location: string): Observable<any> {
    const apiKey = 'AIzaSyCW5-wj-KKXd5UVXxIZ-ZXeEfKumnJVZ0Y';
    const requestUrl = `https://maps.googleapis.com/maps/api/place/textsearch/json?query=${encodeURIComponent(location)}&key=${apiKey}`;
    const wrapperApiUrl = this.host+'url-wrapper/';
    let payload={
      "source_url":requestUrl,
      "response_format":"application/json"
    }
    return this.http.post(wrapperApiUrl,payload);
  }
  getLookups(): Observable<any>{
    let requestUrl = this.host + 'lookups/?active=True';
    return this.http.get(requestUrl);
  }
  searchUser(searchvalue: string, inactiveFlag: string) {
    let requestUrl = this.host.split('v1')[0] + 'v2/systemusers/?' + `active=${inactiveFlag}` + '&search=' + encodeURIComponent(searchvalue) ;
    return this.http.get(requestUrl);
  }
  getPlaceDetailsByLatAndLng(lat: any, lng: any){
    const apiKey = 'AIzaSyCW5-wj-KKXd5UVXxIZ-ZXeEfKumnJVZ0Y';
    let requestUrl=`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
    const wrapperApiUrl = this.host+'url-wrapper/';
    let payload={
      "source_url":requestUrl,
      "response_format":"application/json"
    }
    return this.http.post(wrapperApiUrl,payload);
  }
}
