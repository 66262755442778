import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addComma'
})
export class AddCommaPipe implements PipeTransform {

  transform(value: number | string): string {
    if (value == null) return '';
    const numStr = value.toString();
    const [integerPart, decimalPart] = numStr.split('.');
    const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;
  }

}
