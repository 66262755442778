<div class="invoice-listing">
  <div class="invoice-listing__labels">
    <span></span>
    <span>BIT ID</span>
    <span>Member ID</span>
    <span>Date and Time</span>
    <span>Offer Reference</span>
    <span>Outlet</span>
    <span>Points</span>
    <span class="invoice-listing__labels__align-right"
      >Amount ( {{ currencySymbol }} )</span
    >
    <span></span>
  </div>
  <div
    class="invoice-listing__layout"
    *ngFor="let transaction of transactionDetails"
  >
    <span></span>
    <span class="overflow" title="{{ transaction?.bit_reference }}">{{
      transaction?.bit_reference
    }}</span>
    <span class="overflow" title="{{ transaction?.member_id }}">{{
      transaction?.member_id
    }}</span>
    <span class="overflow" title="{{ transaction?.h_bit_date }}">{{
      transaction?.h_bit_date
    }}</span>
    <span class="overflow" title="{{ transaction?.bonus_offer?.offer_name }}">{{
      transaction?.bonus_offer?.offer_name
    }}</span>
    <span class="overflow" title="{{ transaction?.h_location_name }}">{{
      transaction?.h_location_name
    }}</span>
    <span class="overflow" title="{{ transaction?.base_value | addComma }}">{{
      transaction?.base_value | addComma
    }}</span>
    <span
      class="invoice-listing__layout__align-right"
      title="{{ transaction?.h_bit_amount | addComma }}"
      >{{ transaction?.h_bit_amount | addComma }}</span
    >
    <span></span>
  </div>
</div>
