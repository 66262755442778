<div class="stepper">
    <div class="stepper--profile">
        <div class="stepper--profile__image stepper--profile__image__initial" *ngIf="usersInitial!=''">{{usersInitial}}</div>
        <img class="stepper--profile__image" *ngIf="profileuUrl!=''" [src]="profileuUrl" alt="">
        <div class="stepper--profile__divider"></div>
    </div>
    <div class="stepper--action">
        <div class="stepper--action__step" *ngFor="let step of steps" (click)="navigate(step.key)"
            [class.active]="step.key === activeStep"
            (mouseenter)="showTooltip($event, step)"
             (mouseleave)="hideTooltip()">
            <img class="stepper--action__step--icons" [src]="step.key === activeStep || step.key === hoveredStep ? step.activeImg : step.img" [alt]="step.key">
            <div class="tooltip" *ngIf="step.key === activeTooltipKey">{{ step.value }}</div>
        </div>
    </div>
</div>