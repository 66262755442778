const dropdownData=[
    {
      "countries": [
        {
          "symbol": "$",
          "name": "United States of America",
          "symbol_native": "$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "USD",
          "country_code": "USD",
          "date_format": "MM/DD/YYYY",
          "name_plural": "US dollars",
          "currency_format": "1,234.56",
          "flag_img": "United_States.png",
          "timezones": [
            {
              "name": "America/New_York",
              "value": "America/New_York"
            },
            {
              "name": "America/Detroit",
              "value": "America/Detroit"
            },
            {
              "name": "America/Kentucky/Louisville",
              "value": "America/Kentucky/Louisville"
            },
            {
              "name": "America/Kentucky/Monticello",
              "value": "America/Kentucky/Monticello"
            },
            {
              "name": "America/Indiana/Indianapolis",
              "value": "America/Indiana/Indianapolis"
            },
            {
              "name": "America/Indiana/Marengo",
              "value": "America/Indiana/Marengo"
            },
            {
              "name": "America/Indiana/Knox",
              "value": "America/Indiana/Knox"
            },
            {
              "name": "America/Indiana/Vevay",
              "value": "America/Indiana/Vevay"
            },
            {
              "name": "America/Chicago",
              "value": "America/Chicago"
            },
            {
              "name": "America/Indiana/Vincennes",
              "value": "America/Indiana/Vincennes"
            },
            {
              "name": "America/Indiana/Petersburg",
              "value": "America/Indiana/Petersburg"
            },
            {
              "name": "America/Menominee",
              "value": "America/Menominee"
            },
            {
              "name": "America/North_Dakota/Center",
              "value": "America/North_Dakota/Center"
            },
            {
              "name": "America/North_Dakota/New_Salem",
              "value": "America/North_Dakota/New_Salem"
            },
            {
              "name": "America/Denver",
              "value": "America/Denver"
            },
            {
              "name": "America/Boise",
              "value": "America/Boise"
            },
            {
              "name": "America/Shiprock",
              "value": "America/Shiprock"
            },
            {
              "name": "America/Phoenix",
              "value": "America/Phoenix"
            },
            {
              "name": "America/Los_Angeles",
              "value": "America/Los_Angeles"
            },
            {
              "name": "America/Anchorage",
              "value": "America/Anchorage"
            },
            {
              "name": "America/Juneau",
              "value": "America/Juneau"
            },
            {
              "name": "America/Yakutat",
              "value": "America/Yakutat"
            },
            {
              "name": "America/Nome",
              "value": "America/Nome"
            },
            {
              "name": "America/Adak",
              "value": "America/Adak"
            },
            {
              "name": "Pacific/Honolulu",
              "value": "Pacific/Honolulu"
            }
          ]
        },
        {
          "symbol": "C$",
          "name": "Canada",
          "symbol_native": "$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "CAD",
          "country_code": "CAD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Canadian dollars",
          "currency_format": "1,234.56",
          "flag_img": "Canada.png",
          "timezones": [
            {
              "name": "America/St_Johns",
              "value": "America/St_Johns"
            },
            {
              "name": "America/Halifax",
              "value": "America/Halifax"
            },
            {
              "name": "America/Glace_Bay",
              "value": "America/Glace_Bay"
            },
            {
              "name": "America/Moncton",
              "value": "America/Moncton"
            },
            {
              "name": "America/Goose_Bay",
              "value": "America/Goose_Bay"
            },
            {
              "name": "America/Blanc-Sablon",
              "value": "America/Blanc-Sablon"
            },
            {
              "name": "America/Montreal",
              "value": "America/Montreal"
            },
            {
              "name": "America/Toronto",
              "value": "America/Toronto"
            },
            {
              "name": "America/Nipigon",
              "value": "America/Nipigon"
            },
            {
              "name": "America/Thunder_Bay",
              "value": "America/Thunder_Bay"
            },
            {
              "name": "America/Pangnirtung",
              "value": "America/Pangnirtung"
            },
            {
              "name": "America/Iqaluit",
              "value": "America/Iqaluit"
            },
            {
              "name": "America/Atikokan",
              "value": "America/Atikokan"
            },
            {
              "name": "America/Rankin_Inlet",
              "value": "America/Rankin_Inlet"
            },
            {
              "name": "America/Winnipeg",
              "value": "America/Winnipeg"
            },
            {
              "name": "America/Rainy_River",
              "value": "America/Rainy_River"
            },
            {
              "name": "America/Cambridge_Bay",
              "value": "America/Cambridge_Bay"
            },
            {
              "name": "America/Regina",
              "value": "America/Regina"
            },
            {
              "name": "America/Swift_Current",
              "value": "America/Swift_Current"
            },
            {
              "name": "America/Edmonton",
              "value": "America/Edmonton"
            },
            {
              "name": "America/Yellowknife",
              "value": "America/Yellowknife"
            },
            {
              "name": "America/Inuvik",
              "value": "America/Inuvik"
            },
            {
              "name": "America/Dawson_Creek",
              "value": "America/Dawson_Creek"
            },
            {
              "name": "America/Vancouver",
              "value": "America/Vancouver"
            },
            {
              "name": "America/Whitehorse",
              "value": "America/Whitehorse"
            },
            {
              "name": "America/Dawson",
              "value": "America/Dawson"
            },
            {
              "name": "Canada/Mountain",
              "value": "Canada/Mountain"
            }
          ]
        },
        {
          "symbol": "€",
          "name": "Austria",
          "symbol_native": "€",
          "date_format": "DD/MM/YYYY",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "EUR",
          "country_code": "AUT",
          "name_plural": "euros",
          "currency_format": "1,234,567.89",
          "flag_img": "Austria.png",
          "timezones": [
            {
              "name": "Europe/Vienna",
              "value": "Europe/Vienna"
            }
          ]
        },
        {
          "symbol": "د.إ",
          "name": "United Arab Emirates",
          "symbol_native": "د.إ.‏",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "AED",
          "country_code": "AED",
          "date_format": "DD/MM/YYYY",
          "name_plural": "UAE dirhams",
          "currency_format": "12,345",
          "flag_img": "United_Arab_Emirates.png",
          "timezones": [
            {
              "name": "Asia/Dubai",
              "value": "Asia/Dubai"
            }
          ]
        },
        {
          "symbol": "Af",
          "name": "Afghanistan",
          "symbol_native": "؋",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "AFN",
          "country_code": "AFN",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Afghan Afghanis",
          "currency_format": "1,234.56",
          "flag_img": "Afghanistan.png",
          "timezones": [
            {
              "name": "Asia/Kabul",
              "value": "Asia/Kabul"
            }
          ]
        },
        {
          "symbol": "ALL",
          "name": "Albania",
          "symbol_native": "Lek",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "ALL",
          "country_code": "ALL",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Albanian lekë",
          "currency_format": "1,234.56",
          "flag_img": "Albania.png",
          "timezones": [
            {
              "name": "Europe/Tirane",
              "value": "Europe/Tirane"
            }
          ]
        },
        {
          "symbol": "AMD",
          "name": "Armenia",
          "symbol_native": "դր.",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "AMD",
          "country_code": "AMD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Armenian drams",
          "currency_format": "1,234.56",
          "flag_img": "Armenia.png",
          "timezones": [
            {
              "name": "Asia/Yerevan",
              "value": "Asia/Yerevan"
            }
          ]
        },
        {
          "symbol": "AR$",
          "name": "Argentina",
          "symbol_native": "$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "ARS",
          "country_code": "ARS",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Argentine pesos",
          "currency_format": "1.234,56",
          "flag_img": "Argentina.png",
          "timezones": [
            {
              "name": "America/Argentina/Buenos_Aires",
              "value": "America/Argentina/Buenos_Aires"
            },
            {
              "name": "America/Argentina/Cordoba",
              "value": "America/Argentina/Cordoba"
            },
            {
              "name": "America/Argentina/Jujuy",
              "value": "America/Argentina/Jujuy"
            },
            {
              "name": "America/Argentina/Tucuman",
              "value": "America/Argentina/Tucuman"
            },
            {
              "name": "America/Argentina/Catamarca",
              "value": "America/Argentina/Catamarca"
            },
            {
              "name": "America/Argentina/La_Rioja",
              "value": "America/Argentina/La_Rioja"
            },
            {
              "name": "America/Argentina/San_Juan",
              "value": "America/Argentina/San_Juan"
            },
            {
              "name": "America/Argentina/Mendoza",
              "value": "America/Argentina/Mendoza"
            },
            {
              "name": "America/Argentina/Rio_Gallegos",
              "value": "America/Argentina/Rio_Gallegos"
            },
            {
              "name": "America/Argentina/Ushuaia",
              "value": "America/Argentina/Ushuaia"
            }
          ]
        },
        {
          "symbol": "AU$",
          "name": "Australia",
          "symbol_native": "$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "AUD",
          "country_code": "AUD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Australian dollars",
          "currency_format": "1 234.56",
          "flag_img": "Australia.png",
          "timezones": [
            {
              "name": "Australia/Lord_Howe",
              "value": "Australia/Lord_Howe"
            },
            {
              "name": "Australia/Hobart",
              "value": "Australia/Hobart"
            },
            {
              "name": "Australia/Currie",
              "value": "Australia/Currie"
            },
            {
              "name": "Australia/Melbourne",
              "value": "Australia/Melbourne"
            },
            {
              "name": "Australia/Sydney",
              "value": "Australia/Sydney"
            },
            {
              "name": "Australia/Broken_Hill",
              "value": "Australia/Broken_Hill"
            },
            {
              "name": "Australia/Brisbane",
              "value": "Australia/Brisbane"
            },
            {
              "name": "Australia/Lindeman",
              "value": "Australia/Lindeman"
            },
            {
              "name": "Australia/Adelaide",
              "value": "Australia/Adelaide"
            },
            {
              "name": "Australia/Darwin",
              "value": "Australia/Darwin"
            },
            {
              "name": "Australia/Perth",
              "value": "Australia/Perth"
            }
          ]
        },
        {
          "symbol": "man.",
          "name": "Azerbaijan",
          "symbol_native": "ман.",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "AZN",
          "country_code": "AZN",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Azerbaijani manats",
          "currency_format": "1,234.56",
          "flag_img": "Azerbaijan.png",
          "timezones": [
            {
              "name": "Asia/Baku",
              "value": "Asia/Baku"
            }
          ]
        },
        {
          "symbol": "KM",
          "name": "Bosnia and Herzegovina",
          "symbol_native": "KM",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "BAM",
          "country_code": "BAM",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Bosnia-Herzegovina",
          "currency_format": "1,234.56",
          "flag_img": "Bosnia_and_Herzegovina.png",
          "timezones": [
            {
              "name": "Europe/Sarajevo",
              "value": "Europe/Sarajevo"
            }
          ]
        },
        {
          "symbol": "€",
          "name": "Belgium",
          "symbol_native": "€",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "EUR",
          "country_code": "BEL",
          "date_format": "DD/MM/YYYY",
          "name_plural": "euros",
          "currency_format": "1,234,567.89",
          "flag_img": "Belgium.png",
          "timezones": [
            {
              "name": "Europe/Brussels",
              "value": "Europe/Brussels"
            }
          ]
        },
        {
          "symbol": "Tk",
          "name": "Bangladesh",
          "symbol_native": "৳",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "BDT",
          "country_code": "BDT",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Bangladesh",
          "currency_format": "1,23,456",
          "flag_img": "Bangladesh.png",
          "timezones": [
            {
              "name": "Asia/Dhaka",
              "value": "Asia/Dhaka"
            }
          ]
        },
        {
          "symbol": "BGN",
          "name": "Bulgaria",
          "symbol_native": "лв.",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "BGN",
          "country_code": "BGN",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Bulgarian leva",
          "currency_format": "1 234,56",
          "flag_img": "Bulgaria.png",
          "timezones": [
            {
              "name": "Europe/Sofia",
              "value": "Europe/Sofia"
            }
          ]
        },
        {
          "symbol": "BD",
          "name": "Bahrain",
          "symbol_native": "د.ب.‏",
          "decimal_digits": 3,
          "rounding": 0,
          "code": "BHD",
          "country_code": "BHD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Bahraini dinars",
          "currency_format": "1,234.567",
          "flag_img": "Bahrain.png",
          "timezones": [
            {
              "name": "Asia/Bahrain",
              "value": "Asia/Bahrain"
            }
          ]
        },
        {
          "symbol": "FBu",
          "name": "Burundi",
          "symbol_native": "FBu",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "BIF",
          "country_code": "BIF",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Burundian francs",
          "currency_format": "1,234.56",
          "flag_img": "Bahrain.png",
          "timezones": [
            {
              "name": "Africa/Bujumbura",
              "value": "Africa/Bujumbura"
            }
          ]
        },
        {
          "symbol": "BN$",
          "name": "Brunei",
          "symbol_native": "$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "BND",
          "country_code": "BND",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Brunei dollars",
          "currency_format": "1,234.56",
          "flag_img": "Brunei.png",
          "timezones": [
            {
              "name": "Asia/Brunei",
              "value": "Asia/Brunei"
            }
          ]
        },
        {
          "symbol": "Bs",
          "name": "Bolivia",
          "symbol_native": "Bs",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "BOB",
          "country_code": "BOB",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Bolivian bolivianos",
          "currency_format": "1,234.56",
          "flag_img": "Bolivia.png",
          "timezones": [
            {
              "name": "America/La_Paz",
              "value": "America/La_Paz"
            }
          ]
        },
        {
          "symbol": "R$",
          "name": "Brazil",
          "symbol_native": "R$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "BRL",
          "country_code": "BRL",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Brazilian reals",
          "currency_format": "1.234,56",
          "flag_img": "Brazil.png",
          "timezones": [
            {
              "name": "America/Noronha",
              "value": "America/Noronha"
            },
            {
              "name": "America/Belem",
              "value": "America/Belem"
            },
            {
              "name": "America/Fortaleza",
              "value": "America/Fortaleza"
            },
            {
              "name": "America/Recife",
              "value": "America/Recife"
            },
            {
              "name": "America/Araguaina",
              "value": "America/Araguaina"
            },
            {
              "name": "America/Maceio",
              "value": "America/Maceio"
            },
            {
              "name": "America/Bahia",
              "value": "America/Bahia"
            },
            {
              "name": "America/Sao_Paulo",
              "value": "America/Sao_Paulo"
            },
            {
              "name": "America/Campo_Grande",
              "value": "America/Campo_Grande"
            },
            {
              "name": "America/Cuiaba",
              "value": "America/Cuiaba"
            },
            {
              "name": "America/Porto_Velho",
              "value": "America/Porto_Velho"
            },
            {
              "name": "America/Boa_Vista",
              "value": "America/Boa_Vista"
            },
            {
              "name": "America/Manaus",
              "value": "America/Manaus"
            },
            {
              "name": "America/Eirunepe",
              "value": "America/Eirunepe"
            },
            {
              "name": "America/Rio_Branco",
              "value": "America/Rio_Branco"
            }
          ]
        },
        {
          "symbol": "BWP",
          "name": "Botswana",
          "symbol_native": "P",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "BWP",
          "country_code": "BWP",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Botswanan pulas",
          "currency_format": "1,234.56",
          "flag_img": "Botswana.png",
          "timezones": [
            {
              "name": "Africa/Gaborone",
              "value": "Africa/Gaborone"
            }
          ]
        },
        {
          "symbol": "BYR",
          "name": "Belarus",
          "symbol_native": "BYR",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "BYR",
          "country_code": "BYR",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Belarusian rubles",
          "currency_format": "1,234.56",
          "flag_img": "Belarus.png",
          "timezones": [
            {
              "name": "Europe/Minsk",
              "value": "Europe/Minsk"
            }
          ]
        },
        {
          "symbol": "BZ$",
          "name": "Belize",
          "symbol_native": "$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "BZD",
          "country_code": "BZD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Belize dollars",
          "currency_format": "1,234.56",
          "flag_img": "Belize.png",
          "timezones": [
            {
              "name": "America/Belize",
              "value": "America/Belize"
            }
          ]
        },
        {
          "symbol": "CDF",
          "name": "Democratic Republic of the Congo",
          "symbol_native": "FrCD",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "CDF",
          "country_code": "CDF",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Congolese francs",
          "currency_format": "1,234.56",
          "flag_img": "Democratic_Republic_of_the_Congo.png",
          "timezones": [
            {
              "name": "Africa/Kinshasa",
              "value": "Africa/Kinshasa"
            },
            {
              "name": "Africa/Lubumbashi",
              "value": "Africa/Lubumbashi"
            }
          ]
        },
        {
          "symbol": "CHF",
          "name": "Switzerland",
          "symbol_native": "CHF",
          "decimal_digits": 2,
          "rounding": 0.05,
          "code": "CHF",
          "country_code": "CHF",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Swiss francs",
          "currency_format": "1,234.56",
          "flag_img": "Belize.png",
          "timezones": [
            {
              "name": "Europe/Zurich",
              "value": "Europe/Zurich"
            }
          ]
        },
        {
          "symbol": "CL$",
          "name": "Chile",
          "symbol_native": "$",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "CLP",
          "country_code": "CLP",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Chilean pesos",
          "currency_format": "1.234",
          "flag_img": "Chile.png",
          "timezones": [
            {
              "name": "America/Santiago",
              "value": "America/Santiago"
            },
            {
              "name": "Pacific/Easter",
              "value": "Pacific/Easter"
            }
          ]
        },
        {
          "symbol": "CN¥",
          "name": "China",
          "symbol_native": "CN¥",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "CNY",
          "country_code": "CNY",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Chinese yuan",
          "currency_format": "1,234.56",
          "flag_img": "China.png",
          "timezones": [
            {
              "name": "Asia/Shanghai",
              "value": "Asia/Shanghai"
            },
            {
              "name": "Asia/Harbin",
              "value": "Asia/Harbin"
            },
            {
              "name": "Asia/Chongqing",
              "value": "Asia/Chongqing"
            },
            {
              "name": "Asia/Urumqi",
              "value": "Asia/Urumqi"
            },
            {
              "name": "Asia/Kashgar",
              "value": "AAsia/Kashgar"
            }
          ]
        },
        {
          "symbol": "CO$",
          "name": "Colombia",
          "symbol_native": "$",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "COP",
          "country_code": "COP",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Colombian pesos",
          "currency_format": "1.234,56",
          "flag_img": "Colombia.png",
          "timezones": [
            {
              "name": "America/Bogota",
              "value": "America/Bogota"
            }
          ]
        },
        {
          "symbol": "₡",
          "name": "Costa Rica",
          "symbol_native": "₡",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "CRC",
          "country_code": "CRC",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Costa Rican colóns",
          "currency_format": "1.234,56",
          "flag_img": "Costa_Rica.png",
          "timezones": [
            {
              "name": "America/Costa_Rica",
              "value": "America/Costa_Rica"
            }
          ]
        },
        {
          "symbol": "CV$",
          "name": "Cape Verde",
          "symbol_native": "CV$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "CVE",
          "country_code": "CVE",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Cape Verdean escudos",
          "currency_format": "1,234.56",
          "flag_img": "Cape_Verde.png",
          "timezones": [
            {
              "name": "Atlantic/Cape_Verde",
              "value": "Atlantic/Cape_Verde"
            }
          ]
        },
        {
          "symbol": "Kč",
          "name": "Czech Republic",
          "symbol_native": "Kč",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "CZK",
          "country_code": "CZK",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Czech Republic korunas",
          "currency_format": "1.234,56",
          "flag_img": "Czech_Republic.png",
          "timezones": [
            {
              "name": "Europe/Prague",
              "value": "Europe/Prague"
            }
          ]
        },
        {
          "symbol": "Fdj",
          "name": "Djibouti",
          "symbol_native": "Fdj",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "DJF",
          "country_code": "DJF",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Djiboutian francs",
          "currency_format": "1.234,56",
          "flag_img": "Djibouti.png",
          "timezones": [
            {
              "name": "Africa/Djibouti",
              "value": "Africa/Djibouti"
            }
          ]
        },
        {
          "symbol": "Dkr",
          "name": "Denmark",
          "symbol_native": "kr",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "DKK",
          "country_code": "DKK",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Danish kroner",
          "currency_format": "1.234,56",
          "flag_img": "Denmark.png",
          "timezones": [
            {
              "name": "Europe/Copenhagen",
              "value": "Europe/Copenhagen"
            }
          ]
        },
        {
          "symbol": "RD$",
          "name": "Dominican Republic",
          "symbol_native": "RD$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "DOP",
          "country_code": "DOP",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Dominican pesos",
          "currency_format": "1,234.56",
          "flag_img": "Dominican_Republic.png",
          "timezones": [
            {
              "name": "America/Santo_Domingo",
              "value": "America/Santo_Domingo"
            }
          ]
        },
        {
          "symbol": "DA",
          "name": "Algeria",
          "symbol_native": "د.ج.‏",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "DZD",
          "country_code": "DZD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Algerian dinars",
          "currency_format": "1,234.56",
          "flag_img": "Algeria.png",
          "timezones": [
            {
              "name": "Africa/Algiers",
              "value": "Africa/Algiers"
            }
          ]
        },
        {
          "symbol": "Ekr",
          "name": "Estonia",
          "symbol_native": "kr",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "EEK",
          "country_code": "EEK",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Estonian kroons",
          "currency_format": "1 234.56",
          "flag_img": "Estonia.png",
          "timezones": [
            {
              "name": "Europe/Tallinn",
              "value": "Europe/Tallinn"
            }
          ]
        },
        {
          "symbol": "EGP",
          "name": "Egypt",
          "symbol_native": "ج.م.‏",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "EGP",
          "country_code": "EGP",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Egyptian pounds",
          "currency_format": "1,234.56",
          "flag_img": "Egypt.png",
          "timezones": [
            {
              "name": "Africa/Cairo",
              "value": "Africa/Cairo"
            }
          ]
        },
        {
          "symbol": "Nfk",
          "name": "Eritrea",
          "symbol_native": "Nfk",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "ERN",
          "country_code": "ERN",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Eritrean nakfas",
          "currency_format": "1,234.56",
          "flag_img": "Eritrea.png",
          "timezones": [
            {
              "name": "Africa/Asmera",
              "value": "Africa/Asmera"
            }
          ]
        },
        {
          "symbol": "Br",
          "name": "Ethiopia",
          "symbol_native": "Br",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "ETB",
          "country_code": "ETB",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Ethiopian birrs",
          "currency_format": "1,234.56",
          "flag_img": "Ethiopia.png",
          "timezones": [
            {
              "name": "Africa/Addis_Ababa",
              "value": "Africa/Addis_Ababa"
            }
          ]
        },
        {
          "symbol": "€",
          "name": "France",
          "symbol_native": "€",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "EUR",
          "country_code": "FRA",
          "date_format": "DD/MM/YYYY",
          "name_plural": "euros",
          "currency_format": "1,234,567.89",
          "flag_img": "France.png",
          "timezones": [
            {
              "name": "Europe/Paris",
              "value": "Europe/Paris"
            }
          ]
        },
        {
          "symbol": "€",
          "name": "Finland",
          "symbol_native": "€",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "EUR",
          "country_code": "FIN",
          "date_format": "DD/MM/YYYY",
          "name_plural": "euros",
          "currency_format": "1,234,567.89",
          "flag_img": "Finland.png",
          "timezones": [
            {
              "name": "Europe/Helsinki",
              "value": "Europe/Helsinki"
            }
          ]
        },
        {
          "symbol": "£",
          "name": "United Kingdom",
          "symbol_native": "£",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "GBP",
          "country_code": "GBP",
          "date_format": "DD/MM/YYYY",
          "name_plural": "British pounds sterling",
          "currency_format": "1,234.56",
          "flag_img": "United_Kingdom.png",
          "timezones": [
            {
              "name": "Europe/London",
              "value": "Europe/London"
            }
          ]
        },
        {
          "symbol": "€",
          "name": "Germany",
          "symbol_native": "€",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "EUR",
          "country_code": "DEU",
          "date_format": "DD/MM/YYYY",
          "name_plural": "euros",
          "currency_format": "1,234,567.89",
          "flag_img": "Germany.png",
          "timezones": [
            {
              "name": "Europe/Berlin",
              "value": "Europe/Berlin"
            }
          ]
        },
        {
          "symbol": "GEL",
          "name": "Georgia",
          "symbol_native": "GEL",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "GEL",
          "country_code": "GEL",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Georgian laris",
          "currency_format": "1,234.56",
          "flag_img": "Georgia.png",
          "timezones": [
            {
              "name": "Asia/Tbilisi",
              "value": "Asia/Tbilisi"
            }
          ]
        },
        {
          "symbol": "GH₵",
          "name": "Ghana",
          "symbol_native": "GH₵",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "GHS",
          "country_code": "GHS",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Ghanaian cedis",
          "currency_format": "1,234.56",
          "flag_img": "Ghana.png",
          "timezones": [
            {
              "name": "Africa/Accra",
              "value": "Africa/Accra"
            }
          ]
        },
        {
          "symbol": "FG",
          "name": "Guinea",
          "symbol_native": "FG",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "GNF",
          "country_code": "GNF",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Guinean francs",
          "currency_format": "1,234.56",
          "flag_img": "Guinea.png",
          "timezones": [
            {
              "name": "Africa/Conakry",
              "value": "Africa/Conakry"
            }
          ]
        },
        {
          "symbol": "GTQ",
          "name": "Guatemala",
          "symbol_native": "Q",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "GTQ",
          "country_code": "GTQ",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Guatemalan quetzals",
          "currency_format": "1,234.56",
          "flag_img": "Guatemala.png",
          "timezones": [
            {
              "name": "America/Guatemala",
              "value": "America/Guatemala"
            }
          ]
        },
        {
          "symbol": "HK$",
          "name": "Hong Kong",
          "symbol_native": "$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "HKD",
          "country_code": "HKD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Hong Kong dollars",
          "currency_format": "1,234.56",
          "flag_img": "Hong_Kong.png",
          "timezones": [
            {
              "name": "Asia/Hong_Kong",
              "value": "Asia/Hong_Kong"
            }
          ]
        },
        {
          "symbol": "HNL",
          "name": "Honduras",
          "symbol_native": "L",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "HNL",
          "country_code": "HNL",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Honduran lempiras",
          "currency_format": "1,234.56",
          "flag_img": "Honduras.png",
          "timezones": [
            {
              "name": "America/Tegucigalpa",
              "value": "America/Tegucigalpa"
            }
          ]
        },
        {
          "symbol": "kn",
          "name": "Croatia",
          "symbol_native": "kn",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "HRK",
          "country_code": "HRK",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Croatian kunas",
          "currency_format": "1.234,56",
          "flag_img": "Croatia.png",
          "timezones": [
            {
              "name": "Europe/Zagreb",
              "value": "Europe/Zagreb"
            }
          ]
        },
        {
          "symbol": "Ft",
          "name": "Hungary",
          "symbol_native": "Ft",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "HUF",
          "country_code": "HUF",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Hungarian forints",
          "currency_format": "1.234",
          "flag_img": "Hungary.png",
          "timezones": [
            {
              "name": "Europe/Budapest",
              "value": "Europe/Budapest"
            }
          ]
        },
        {
          "symbol": "Rp",
          "name": "Indonesia",
          "symbol_native": "Rp",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "IDR",
          "country_code": "IDR",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Indonesian rupiahs",
          "currency_format": "1.234,56",
          "flag_img": "Indonesia.png",
          "timezones": [
            {
              "name": "Asia/Jakarta",
              "value": "Asia/Jakarta"
            },
            {
              "name": "Asia/Pontianak",
              "value": "Asia/Pontianak"
            },
            {
              "name": "Asia/Makassar",
              "value": "Asia/Makassar"
            },
            {
              "name": "Asia/Jayapura",
              "value": "Asia/Jayapura"
            }
          ]
        },
        {
          "symbol": "₪",
          "name": "Israel",
          "symbol_native": "₪",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "ILS",
          "country_code": "ILS",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Israeli new sheqels",
          "currency_format": "1,234.56",
          "flag_img": "Israel.png",
          "timezones": [
            {
              "name": "Asia/Jerusalem",
              "value": "Asia/Jerusalem"
            }
          ]
        },
        {
          "symbol": "₹",
          "name": "India",
          "symbol_native": "টকা",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "INR",
          "country_code": "INR",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Indian rupees",
          "currency_format": "1,23,456.78",
          "flag_img": "India.png",
          "timezones": [
            {
              "name": "Asia/Calcutta",
              "value": "Asia/Calcutta"
            }
          ]
        },
        {
          "symbol": "€",
          "name": "Ireland",
          "symbol_native": "€",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "IE",
          "country_code": "IE",
          "date_format": "DD/MM/YYYY",
          "name_plural": "euros",
          "currency_format": "1,234,567.89",
          "flag_img": "Ireland.png",
          "timezones": [
            {
              "name": "Europe/Dublin",
              "value": "Europe/Dublin"
            }
          ]
        },
        {
          "symbol": "IQD",
          "name": "Iraq",
          "symbol_native": "د.ع.‏",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "IQD",
          "country_code": "IQD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Iraqi dinars",
          "currency_format": "Millions",
          "flag_img": "Iraq.png",
          "timezones": [
            {
              "name": "Asia/Baghdad",
              "value": "Asia/Baghdad"
            }
          ]
        },
        {
          "symbol": "IRR",
          "name": "Iran",
          "symbol_native": "﷼",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "IRR",
          "country_code": "IRR",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Iranian rials",
          "currency_format": "1,234.56",
          "flag_img": "Iran.png",
          "timezones": [
            {
              "name": "Asia/Tehran",
              "value": "Asia/Tehran"
            }
          ]
        },
        {
          "symbol": "Ikr",
          "name": "Iceland",
          "symbol_native": "kr",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "ISK",
          "country_code": "ISK",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Icelandic krónur",
          "currency_format": "1.234",
          "flag_img": "Iceland.png",
          "timezones": [
            {
              "name": "Atlantic/Reykjavik",
              "value": "Atlantic/Reykjavik"
            }
          ]
        },
        {
          "symbol": "€",
          "name": "Italy",
          "symbol_native": "€",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "EUR",
          "country_code": "ITA",
          "date_format": "DD/MM/YYYY",
          "name_plural": "euros",
          "currency_format": "1,234,567.89",
          "flag_img": "Italy.png",
          "timezones": [
            {
              "name": "Europe/Rome",
              "value": "Europe/Rome"
            }
          ]
        },
        {
          "symbol": "J$",
          "name": "Jamaica",
          "symbol_native": "$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "JMD",
          "country_code": "JMD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Jamaican dollars",
          "currency_format": "1,234.56",
          "flag_img": "Jamaica.png",
          "timezones": [
            {
              "name": "America/Jamaica",
              "value": "America/Jamaica"
            }
          ]
        },
        {
          "symbol": "JD",
          "name": "Jordan",
          "symbol_native": "د.أ.‏",
          "decimal_digits": 3,
          "rounding": 0,
          "code": "JOD",
          "country_code": "JOD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Jordanian dinars",
          "currency_format": "1,234.567",
          "flag_img": "Jordan.png",
          "timezones": [
            {
              "name": "Asia/Amman",
              "value": "Asia/Amman"
            }
          ]
        },
        {
          "symbol": "¥",
          "name": "Japan",
          "symbol_native": "￥",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "JPY",
          "country_code": "JPY",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Japanese yen",
          "currency_format": "1,234",
          "flag_img": "Japan.png",
          "timezones": [
            {
              "name": "Asia/Tokyo",
              "value": "Asia/Tokyo"
            }
          ]
        },
        {
          "symbol": "Ksh",
          "name": "Kenya",
          "symbol_native": "Ksh",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "KES",
          "country_code": "KES",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Kenyan shillings",
          "currency_format": "1,234.56",
          "flag_img": "Kenya.png",
          "timezones": [
            {
              "name": "Africa/Nairobi",
              "value": "Africa/Nairobi"
            }
          ]
        },
        {
          "symbol": "KHR",
          "name": "Cambodia",
          "symbol_native": "៛",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "KHR",
          "country_code": "KHR",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Cambodian riels",
          "currency_format": "1,234.56",
          "flag_img": "Cambodia.png",
          "timezones": [
            {
              "name": "Asia/Phnom_Penh",
              "value": "Asia/Phnom_Penh"
            }
          ]
        },
        {
          "symbol": "CF",
          "name": "Comoros",
          "symbol_native": "FC",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "KMF",
          "country_code": "KMF",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Comorian francs",
          "currency_format": "1,234.56",
          "flag_img": "Comoros.png",
          "timezones": [
            {
              "name": "Indian/Comoro",
              "value": "Indian/Comoro"
            }
          ]
        },
        {
          "symbol": "₩",
          "name": "South Korea",
          "symbol_native": "₩",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "KRW",
          "country_code": "KRW",
          "date_format": "DD/MM/YYYY",
          "name_plural": "South Korean won",
          "currency_format": "1,234",
          "flag_img": "South_Korea.png",
          "timezones": [
            {
              "name": "Asia/Seoul",
              "value": "Asia/Seoul"
            }
          ]
        },
        {
          "symbol": "KD",
          "name": "Kuwait",
          "symbol_native": "د.ك.‏",
          "decimal_digits": 3,
          "rounding": 0,
          "code": "KWD",
          "country_code": "KWD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Kuwaiti dinars",
          "currency_format": "1,234.567",
          "flag_img": "Kuwait.png",
          "timezones": [
            {
              "name": "Asia/Kuwait",
              "value": "Asia/Kuwait"
            }
          ]
        },
        {
          "symbol": "KZT",
          "name": "Kazakhstan",
          "symbol_native": "тңг.",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "KZT",
          "country_code": "KZT",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Kazakhstani tenges",
          "currency_format": "1,234.567",
          "flag_img": "Kazakhstan.png",
          "timezones": [
            {
              "name": "Asia/Almaty",
              "value": "Asia/Almaty"
            },
            {
              "name": "Asia/Qyzylorda",
              "value": "Asia/Qyzylorda"
            },
            {
              "name": "Asia/Aqtobe",
              "value": "Asia/Aqtobe"
            },
            {
              "name": "Asia/Aqtau",
              "value": "Asia/Aqtau"
            },
            {
              "name": "Asia/Oral",
              "value": "Asia/Oral"
            }
          ]
        },
        {
          "symbol": "LB£",
          "name": "Lebanon",
          "symbol_native": "ل.ل.‏",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "LBP",
          "country_code": "LBP",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Lebanese pounds",
          "currency_format": "1,234.567",
          "flag_img": "Lebanon.png",
          "timezones": [
            {
              "name": "Asia/Beirut",
              "value": "Asia/Beirut"
            }
          ]
        },
        {
          "symbol": "SLRs",
          "name": "Sri Lanka",
          "symbol_native": "SL Re",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "LKR",
          "country_code": "LKR",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Sri Lankan rupees",
          "currency_format": "1,23,456.78",
          "flag_img": "Sri_Lanka.png",
          "timezones": [
            {
              "name": "Asia/Colombo",
              "value": "Asia/Colombo"
            }
          ]
        },
        {
          "symbol": "Lt",
          "name": "Lithuania",
          "symbol_native": "Lt",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "LTL",
          "country_code": "LTL",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Lithuanian litai",
          "currency_format": "1 234,56",
          "flag_img": "Lithuania.png",
          "timezones": [
            {
              "name": "Europe/Vilnius",
              "value": "Europe/Vilnius"
            }
          ]
        },
        {
          "symbol": "Ls",
          "name": "Latvia",
          "symbol_native": "Ls",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "LVL",
          "country_code": "LVL",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Latvian lati",
          "currency_format": "1,234.56",
          "flag_img": "Latvia.png",
          "timezones": [
            {
              "name": "Europe/Riga",
              "value": "Europe/Riga"
            }
          ]
        },
        {
          "symbol": "LD",
          "name": "Libya",
          "symbol_native": "د.ل.‏",
          "decimal_digits": 3,
          "rounding": 0,
          "code": "LYD",
          "country_code": "LYD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Libyan dinars",
          "currency_format": "1,234.56",
          "flag_img": "Libya.png",
          "timezones": [
            {
              "name": "Africa/Tripoli",
              "value": "Africa/Tripoli"
            }
          ]
        },
        {
          "symbol": "MAD",
          "name": "Morocco",
          "symbol_native": "د.م.‏",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "MAD",
          "country_code": "MAD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Moroccan dirhams",
          "currency_format": "1,234.56",
          "flag_img": "Morocco.png",
          "timezones": [
            {
              "name": "Africa/Casablanca",
              "value": "Africa/Casablanca"
            }
          ]
        },
        {
          "symbol": "MDL",
          "name": "Moldova",
          "symbol_native": "MDL",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "MDL",
          "country_code": "MDL",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Moldovan lei",
          "currency_format": "1,234.56",
          "flag_img": "Moldova.png",
          "timezones": [
            {
              "name": "Europe/Chisinau",
              "value": "Europe/Chisinau"
            }
          ]
        },
        {
          "symbol": "MGA",
          "name": "Madagascar",
          "symbol_native": "MGA",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "MGA",
          "country_code": "MGA",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Malagasy Ariaries",
          "currency_format": "1,234.56",
          "flag_img": "Madagascar.png",
          "timezones": [
            {
              "name": "Indian/Antananarivo",
              "value": "Indian/Antananarivo"
            }
          ]
        },
        {
          "symbol": "MKD",
          "name": "Macedonia",
          "symbol_native": "MKD",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "MKD",
          "country_code": "MKD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Macedonian denari",
          "currency_format": "1,234.56",
          "flag_img": "Macedonia.png",
          "timezones": [
            {
              "name": "Europe/Skopje",
              "value": "Europe/Skopje"
            }
          ]
        },
        {
          "symbol": "MMK",
          "name": "Myanmar (Burma)",
          "symbol_native": "K",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "MMK",
          "country_code": "MMK",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Myanma kyats",
          "currency_format": "1,234.56",
          "flag_img": "Myanmar.png",
          "timezones": [
            {
              "name": "Asia/Rangoon",
              "value": "Asia/Rangoon"
            }
          ]
        },
        {
          "symbol": "MOP$",
          "name": "Macau",
          "symbol_native": "MOP$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "MOP",
          "country_code": "MOP",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Macanese patacas",
          "currency_format": "1,234.56",
          "flag_img": "Macau.png",
          "timezones": [
            {
              "name": "Asia/Macau",
              "value": "Asia/Macau"
            }
          ]
        },
        {
          "symbol": "MURs",
          "name": "Mauritius",
          "symbol_native": "MURs",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "MUR",
          "country_code": "MUR",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Mauritian rupees",
          "currency_format": "1,234",
          "flag_img": "Mauritius.png",
          "timezones": [
            {
              "name": "Indian/Mauritius",
              "value": "Indian/Mauritius"
            }
          ]
        },
        {
          "symbol": "MX$",
          "name": "Mexico",
          "symbol_native": "$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "MXN",
          "country_code": "MXN",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Mexican pesos",
          "currency_format": "1,234.56",
          "flag_img": "Mexico.png",
          "timezones": [
            {
              "name": "America/Mexico_City",
              "value": "America/Mexico_City"
            },
            {
              "name": "America/Cancun",
              "value": "America/Cancun"
            },
            {
              "name": "America/Merida",
              "value": "America/Merida"
            },
            {
              "name": "America/Monterrey",
              "value": "America/Monterrey"
            },
            {
              "name": "America/Mazatlan",
              "value": "America/Mazatlan"
            },
            {
              "name": "America/Chihuahua",
              "value": "America/Chihuahua"
            },
            {
              "name": "America/Hermosillo",
              "value": "America/Hermosillo"
            },
            {
              "name": "America/Tijuana",
              "value": "America/Tijuana"
            }
          ]
        },
        {
          "symbol": "RM",
          "name": "Malaysia",
          "symbol_native": "RM",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "MYR",
          "country_code": "MYR",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Malaysian ringgits",
          "currency_format": "1,234.56",
          "flag_img": "Malaysia.png",
          "timezones": [
            {
              "name": "Asia/Kuala_Lumpur",
              "value": "Asia/Kuala_Lumpur"
            },
            {
              "name": "Asia/Kuching",
              "value": "Asia/Kuching"
            }
          ]
        },
        {
          "symbol": "MTn",
          "name": "Mozambique",
          "symbol_native": "MTn",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "MZN",
          "country_code": "MZN",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Mozambican meticals",
          "currency_format": "1.234,56",
          "flag_img": "Mozambique.png",
          "timezones": [
            {
              "name": "Africa/Maputo",
              "value": "Africa/Maputo"
            }
          ]
        },
        {
          "symbol": "N$",
          "name": "Namibia",
          "symbol_native": "N$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "NAD",
          "country_code": "NAD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Namibian dollars",
          "currency_format": "1.234,56",
          "flag_img": "Namibia.png",
          "timezones": [
            {
              "name": "Africa/Windhoek",
              "value": "Africa/Windhoek"
            }
          ]
        },
        {
          "symbol": "₦",
          "name": "Nigeria",
          "symbol_native": "₦",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "NGN",
          "country_code": "NGN",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Nigerian nairas",
          "currency_format": "1.234,56",
          "flag_img": "Nigeria.png",
          "timezones": [
            {
              "name": "Africa/Lagos",
              "value": "Africa/Lagos"
            }
          ]
        },
        {
          "symbol": "C$",
          "name": "Nicaragua",
          "symbol_native": "C$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "NIO",
          "country_code": "NIO",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Nicaraguan córdobas",
          "currency_format": "1.234,56",
          "flag_img": "Nicaragua.png",
          "timezones": [
            {
              "name": "America/Managua",
              "value": "America/Managua"
            }
          ]
        },
        {
          "symbol": "Nkr",
          "name": "Norway",
          "symbol_native": "kr",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "NOK",
          "country_code": "NOK",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Norwegian kroner",
          "currency_format": "1.234,56",
          "flag_img": "Norway.png",
          "timezones": [
            {
              "name": "Europe/Oslo",
              "value": "Europe/Oslo"
            }
          ]
        },
        {
          "symbol": "NPRs",
          "name": "Nepal",
          "symbol_native": "नेरू",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "NPR",
          "country_code": "NPR",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Nepalese rupees",
          "currency_format": "1,234.56",
          "flag_img": "Nepal.png",
          "timezones": [
            {
              "name": "Asia/Katmandu",
              "value": "Asia/Katmandu"
            }
          ]
        },
        {
          "symbol": "NZ$",
          "name": "New Zealand",
          "symbol_native": "$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "NZD",
          "country_code": "NZD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "New Zealand dollars",
          "currency_format": "1,234.56",
          "flag_img": "New_Zealand.png",
          "timezones": [
            {
              "name": "Pacific/Auckland",
              "value": "Pacific/Auckland"
            },
            {
              "name": "Pacific/Chatham",
              "value": "Pacific/Chatham"
            }
          ]
        },
        {
          "symbol": "OMR",
          "name": "Oman",
          "symbol_native": "ر.ع.‏",
          "decimal_digits": 3,
          "rounding": 0,
          "code": "OMR",
          "country_code": "OMR",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Omani rials",
          "currency_format": "1,234.567",
          "flag_img": "Oman.png",
          "timezones": [
            {
              "name": "Asia/Muscat",
              "value": "Asia/Muscat"
            }
          ]
        },
        {
          "symbol": "B/.",
          "name": "Panama",
          "symbol_native": "B/.",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "PAB",
          "country_code": "PAB",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Panamanian balboas",
          "currency_format": "1,234.56",
          "flag_img": "Panama.png",
          "timezones": [
            {
              "name": "America/Panama",
              "value": "America/Panama"
            }
          ]
        },
        {
          "symbol": "K",
          "name": "Papua New Guinea",
          "symbol_native": "K",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "PGK",
          "country_code": "PNG",
          "date_format": "DD/MM/YYYY",
          "name_plural": "KINA",
          "currency_format": "1,234.56",
          "flag_img": "Papua_New_Guinea.png",
          "timezones": [
            {
              "name": "Pacific/Port_Moresby",
              "value": "Pacific/Port_Moresby"
            }
          ]
        },
        {
          "symbol": "S/.",
          "name": "Peru",
          "symbol_native": "S/.",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "PEN",
          "country_code": "PEN",
          "date_format": "DD/MM/YYYY",
          "name_plural": "1,234.56",
          "currency_format": "1,234.56",
          "flag_img": "Peru.png",
          "timezones": [
            {
              "name": "America/Lima",
              "value": "America/Lima"
            }
          ]
        },
        {
          "symbol": "₱",
          "name": "Philippines",
          "symbol_native": "₱",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "PHP",
          "country_code": "PHP",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Philippine pesos",
          "currency_format": "1,234.56",
          "flag_img": "Philippines.png",
          "timezones": [
            {
              "name": "Asia/Manila",
              "value": "Asia/Manila"
            }
          ]
        },
        {
          "symbol": "PKRs",
          "name": "Pakistan",
          "symbol_native": "₨",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "PKR",
          "country_code": "PKR",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Pakistani rupees",
          "currency_format": "1,234.56",
          "flag_img": "Pakistan.png",
          "timezones": [
            {
              "name": "Asia/Karachi",
              "value": "Asia/Karachi"
            }
          ]
        },
        {
          "symbol": "zł",
          "name": "Poland",
          "symbol_native": "zł",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "PLN",
          "country_code": "PLN",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Polish zlotys",
          "currency_format": "1 234,56",
          "flag_img": "Poland.png",
          "timezones": [
            {
              "name": "Europe/Warsaw",
              "value": "Europe/Warsaw"
            }
          ]
        },
        {
          "symbol": "€",
          "name": "Portugal",
          "symbol_native": "€",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "EUR",
          "country_code": "PRT",
          "date_format": "DD/MM/YYYY",
          "name_plural": "euros",
          "currency_format": "1,234,567.89",
          "flag_img": "Portugal.png",
          "timezones": [
            {
              "name": "Europe/Lisbon",
              "value": "Europe/Lisbon"
            },
            {
              "name": "Atlantic/Madeira",
              "value": "Atlantic/Madeira"
            },
            {
              "name": "Atlantic/Azores",
              "value": "Atlantic/Azores"
            }
          ]
        },
        {
          "symbol": "₲",
          "name": "Paraguay",
          "symbol_native": "₲",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "PYG",
          "country_code": "PYG",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Paraguayan guaranis",
          "currency_format": "1,234.56",
          "flag_img": "Paraguay.png",
          "timezones": [
            {
              "name": "America/Asuncion",
              "value": "America/Asuncion"
            }
          ]
        },
        {
          "symbol": "QR",
          "name": "Qatar",
          "symbol_native": "ر.ق.‏",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "QAR",
          "country_code": "QAR",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Qatari rials",
          "currency_format": "1,234.56",
          "flag_img": "Qatar.png",
          "timezones": [
            {
              "name": "Asia/Qatar",
              "value": "Asia/Qatar"
            }
          ]
        },
        {
          "symbol": "RON",
          "name": "Romania",
          "symbol_native": "RON",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "RON",
          "country_code": "RON",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Romanian lei",
          "currency_format": "1.234,56",
          "flag_img": "Romania.png",
          "timezones": [
            {
              "name": "Europe/Bucharest",
              "value": "Europe/Bucharest"
            }
          ]
        },
        {
          "symbol": "din.",
          "name": "Serbia",
          "symbol_native": "дин.",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "RSD",
          "country_code": "RSD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Serbian dinars",
          "currency_format": "1.234,56",
          "flag_img": "Serbia.png",
          "timezones": [
            {
              "name": "Europe/Belgrade",
              "value": "Europe/Belgrade"
            }
          ]
        },
        {
          "symbol": "RUB",
          "name": "Russia",
          "symbol_native": "руб.",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "RUB",
          "country_code": "RUB",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Russian rubles",
          "currency_format": "1.234,56",
          "flag_img": "Russia.png",
          "timezones": [
            {
              "name": "Europe/Kaliningrad",
              "value": "Europe/Kaliningrad"
            },
            {
              "name": "Europe/Moscow",
              "value": "Europe/Moscow"
            },
            {
              "name": "Europe/Volgograd",
              "value": "Europe/Volgograd"
            },
            {
              "name": "Europe/Samara",
              "value": "Europe/Samara"
            },
            {
              "name": "Asia/Yekaterinburg",
              "value": "Asia/Yekaterinburg"
            },
            {
              "name": "Asia/Omsk",
              "value": "Asia/Omsk"
            },
            {
              "name": "Asia/Novosibirsk",
              "value": "Asia/Novosibirsk"
            },
            {
              "name": "Asia/Krasnoyarsk",
              "value": "Asia/Krasnoyarsk"
            },
            {
              "name": "Asia/Irkutsk",
              "value": "Asia/Irkutsk"
            },
            {
              "name": "Asia/Yakutsk",
              "value": "Asia/Yakutsk"
            },
            {
              "name": "Asia/Vladivostok",
              "value": "Asia/Vladivostok"
            },
            {
              "name": "Asia/Sakhalin",
              "value": "Asia/Sakhalin"
            },
            {
              "name": "Asia/Magadan",
              "value": "Asia/Magadan"
            },
            {
              "name": "Asia/Kamchatka",
              "value": "Asia/Kamchatka"
            },
            {
              "name": "Asia/Anadyr",
              "value": "Asia/Anadyr"
            }
          ]
        },
        {
          "symbol": "RWF",
          "name": "Rwanda",
          "symbol_native": "FR",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "RWF",
          "country_code": "RWF",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Rwandan francs",
          "currency_format": "1,234.56",
          "flag_img": "Rwanda.png",
          "timezones": [
            {
              "name": "Africa/Kigali",
              "value": "Africa/Kigali"
            }
          ]
        },
        {
          "symbol": "SR",
          "name": "Saudi Arabia",
          "symbol_native": "ر.س.‏",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "SAR",
          "country_code": "SAR",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Saudi riyals",
          "currency_format": "1,234.56",
          "flag_img": "Saudi_Arabia.png",
          "timezones": [
            {
              "name": "Asia/Riyadh",
              "value": "Asia/Riyadh"
            }
          ]
        },
        {
          "symbol": "€",
          "name": "Spain",
          "symbol_native": "€",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "EUR",
          "country_code": "ESP",
          "date_format": "DD/MM/YYYY",
          "name_plural": "euros",
          "currency_format": "1,234,567.89",
          "flag_img": "Spain.png",
          "timezones": [
            {
              "name": "Europe/Madrid",
              "value": "Europe/Madrid"
            },
            {
              "name": "Africa/Ceuta",
              "value": "Africa/Ceuta"
            },
            {
              "name": "Atlantic/Canary",
              "value": "Atlantic/Canary"
            }
          ]
        },
        {
          "symbol": "SDG",
          "name": "Sudan",
          "symbol_native": "SDG",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "SDG",
          "country_code": "SDG",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Sudanese pounds",
          "currency_format": "1.234,56",
          "flag_img": "Sudan.png",
          "timezones": [
            {
              "name": "Africa/Khartoum",
              "value": "Africa/Khartoum"
            }
          ]
        },
        {
          "symbol": "Skr",
          "name": "Sweden",
          "symbol_native": "kr",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "SEK",
          "country_code": "SEK",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Swedish kronor",
          "currency_format": "Millions",
          "flag_img": "Sweden.png",
          "timezones": [
            {
              "name": "Europe/Stockholm",
              "value": "Europe/Stockholm"
            }
          ]
        },
        {
          "symbol": "S$",
          "name": "Singapore",
          "symbol_native": "$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "SGD",
          "country_code": "SGD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Singapore dollars",
          "currency_format": "1,234.56",
          "flag_img": "Singapore.png",
          "timezones": [
            {
              "name": "Asia/Singapore",
              "value": "Asia/Singapore"
            }
          ]
        },
        {
          "symbol": "Ssh",
          "name": "Somalia",
          "symbol_native": "Ssh",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "SOS",
          "country_code": "SOS",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Somali shillings",
          "currency_format": "1,234.56",
          "flag_img": "Somalia.png",
          "timezones": [
            {
              "name": "Africa/Mogadishu",
              "value": "Africa/Mogadishu"
            }
          ]
        },
        {
          "symbol": "SY£",
          "name": "Syria",
          "symbol_native": "ل.س.‏",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "SYP",
          "country_code": "SYP",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Syrian pounds",
          "currency_format": "1,234.56",
          "flag_img": "Syria.png",
          "timezones": [
            {
              "name": "Asia/Damascus",
              "value": "Asia/Damascus"
            }
          ]
        },
        {
          "symbol": "฿",
          "name": "Thailand",
          "symbol_native": "฿",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "THB",
          "country_code": "THB",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Thai baht",
          "currency_format": "1,234.56",
          "flag_img": "Thailand.png",
          "timezones": [
            {
              "name": "Asia/Bangkok",
              "value": "Asia/Bangkok"
            }
          ]
        },
        {
          "symbol": "DT",
          "name": "Tunisia",
          "symbol_native": "د.ت.‏",
          "decimal_digits": 3,
          "rounding": 0,
          "code": "TND",
          "country_code": "TND",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Tunisian dinars",
          "currency_format": "1,234.56",
          "flag_img": "Tunisia.png",
          "timezones": [
            {
              "name": "Africa/Tunis",
              "value": "Africa/Tunis"
            }
          ]
        },
        {
          "symbol": "T$",
          "name": "Tonga",
          "symbol_native": "T$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "TOP",
          "country_code": "TOP",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Tongan paʻanga",
          "currency_format": "1,234.56",
          "flag_img": "Tonga.png",
          "timezones": [
            {
              "name": "Pacific/Tongatapu",
              "value": "Pacific/Tongatapu"
            }
          ]
        },
        {
          "symbol": "TL",
          "name": "Turkey",
          "symbol_native": "TL",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "TRY",
          "country_code": "TRY",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Turkish Lira",
          "currency_format": "1,234.56",
          "flag_img": "Turkey.png",
          "timezones": [
            {
              "name": "Europe/Istanbul",
              "value": "Europe/Istanbul"
            }
          ]
        },
        {
          "symbol": "TT$",
          "name": "Trinidad and Tobago",
          "symbol_native": "$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "TTD",
          "country_code": "TTD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Trinidad and Tobago dollars",
          "currency_format": "1,234.56",
          "flag_img": "Trinidad_and_Tobago.png",
          "timezones": [
            {
              "name": "America/Port_of_Spain",
              "value": "America/Port_of_Spain"
            }
          ]
        },
        {
          "symbol": "NT$",
          "name": "Taiwan",
          "symbol_native": "NT$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "TWD",
          "country_code": "TWD",
          "date_format": "DD/MM/YYYY",
          "name_plural": "New Taiwan dollars",
          "currency_format": "1,234.56",
          "flag_img": "Taiwan.png",
          "timezones": [
            {
              "name": "Asia/Taipei",
              "value": "Asia/Taipei"
            }
          ]
        },
        {
          "symbol": "TSh",
          "name": "Tanzania",
          "symbol_native": "TSh",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "TZS",
          "country_code": "TZS",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Tanzanian shillings",
          "currency_format": "1,234.56",
          "flag_img": "Tanzania.png",
          "timezones": [
            {
              "name": "Africa/Dar_es_Salaam",
              "value": "Africa/Dar_es_Salaam"
            }
          ]
        },
        {
          "symbol": "₴",
          "name": "Ukraine",
          "symbol_native": "₴",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "UAH",
          "country_code": "UAH",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Ukrainian hryvnias",
          "currency_format": "1 234,56",
          "flag_img": "Ukraine.png",
          "timezones": [
            {
              "name": "Europe/Kiev",
              "value": "Europe/Kiev"
            },
            {
              "name": "Europe/Uzhgorod",
              "value": "Europe/Uzhgorod"
            },
            {
              "name": "Europe/Zaporozhye",
              "value": "Europe/Zaporozhye"
            },
            {
              "name": "Europe/Simferopol",
              "value": "Europe/Simferopol"
            }
          ]
        },
        {
          "symbol": "USh",
          "name": "Uganda",
          "symbol_native": "USh",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "UGX",
          "country_code": "UGX",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Ugandan shillings",
          "currency_format": "1,234.56",
          "flag_img": "Uganda.png",
          "timezones": [
            {
              "name": "Africa/Kampala",
              "value": "Africa/Kampala"
            }
          ]
        },
        {
          "symbol": "$U",
          "name": "Uruguay",
          "symbol_native": "$",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "UYU",
          "country_code": "UYU",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Uruguayan pesos",
          "currency_format": "1.234,56",
          "flag_img": "Uruguay.png",
          "timezones": [
            {
              "name": "America/Montevideo",
              "value": "America/Montevideo"
            }
          ]
        },
        {
          "symbol": "UZS",
          "name": "Uzbekistan",
          "symbol_native": "UZS",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "UZS",
          "country_code": "UZS",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Uzbekistan som",
          "currency_format": "1,234.56",
          "flag_img": "Uzbekistan.png",
          "timezones": [
            {
              "name": "Asia/Samarkand",
              "value": "Asia/Samarkand"
            },
            {
              "name": "Asia/Tashkent",
              "value": "Asia/Tashkent"
            }
          ]
        },
        {
          "symbol": "Bs.F.",
          "name": "Venezuela",
          "symbol_native": "Bs.F.",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "VEF",
          "country_code": "VEF",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Venezuelan bolívars",
          "currency_format": "1.234,56",
          "flag_img": "Venezuela.png",
          "timezones": [
            {
              "name": "America/Caracas",
              "value": "America/Caracas"
            }
          ]
        },
        {
          "symbol": "₫",
          "name": "Vietnam",
          "symbol_native": "₫",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "VND",
          "country_code": "VND",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Vietnamese dong",
          "currency_format": "1.234,56",
          "flag_img": "Vietnam.png",
          "timezones": [
            {
              "name": "Asia/Saigon",
              "value": "Asia/Saigon"
            }
          ]
        },
        {
          "symbol": "FCFA",
          "name": "Central African Republic",
          "symbol_native": "FCFA",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "XAF",
          "country_code": "XAF",
          "date_format": "DD/MM/YYYY",
          "name_plural": "CFA francs BEAC",
          "currency_format": "1,234.56",
          "flag_img": "Central_African_Republic.png",
          "timezones": [
            {
              "name": "Africa/Bangui",
              "value": "Africa/Bangui"
            }
          ]
        },
        {
          "symbol": "YR",
          "name": "Yemen",
          "symbol_native": "ر.ي.‏",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "YER",
          "country_code": "YER",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Yemeni rials",
          "currency_format": "1.234,56",
          "flag_img": "Yemen.png",
          "timezones": [
            {
              "name": "Asia/Aden",
              "value": "Asia/Aden"
            }
          ]
        },
        {
          "symbol": "R",
          "name": "South Africa",
          "symbol_native": "R",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "ZAR",
          "country_code": "ZAR",
          "date_format": "DD/MM/YYYY",
          "name_plural": "South African rand",
          "currency_format": "1 234.56",
          "flag_img": "South_Africa.png",
          "timezones": [
            {
              "name": "Africa/Johannesburg",
              "value": "Africa/Johannesburg"
            }
          ]
        },
        {
          "symbol": "ZK",
          "name": "Zambia",
          "symbol_native": "ZK",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "ZMK",
          "country_code": "ZMK",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Zambian kwachas",
          "currency_format": "1 234.56",
          "flag_img": "Zambia.png",
          "timezones": [
            {
              "name": "Africa/Lusaka",
              "value": "Africa/Lusaka"
            }
          ]
        },
        {
          "symbol": "ZB",
          "name": "Zimbabwe",
          "symbol_native": "ZB",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "ZB",
          "country_code": "ZB",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Zimbabwe",
          "currency_format": "1 234.56",
          "flag_img": "Zimbabwe.png",
          "timezones": [
            {
              "name": "Africa/Harare",
              "value": "Africa/Harare"
            }
          ]
        },
        {
          "symbol": "€",
          "name": "Monaco",
          "symbol_native": "€",
          "decimal_digits": 2,
          "rounding": 0,
          "code": "EUR",
          "country_code": "MCO",
          "date_format": "DD/MM/YYYY",
          "name_plural": "euros",
          "currency_format": "1,234,567.89",
          "flag_img": "Monaco.png",
          "timezones": [
            {
              "name": "Europe/Monaco",
              "value": "Europe/Monaco"
            }
          ]
        },
        {
          "symbol": "ƒ",
          "name": "Aruba",
          "symbol_native": "ƒ",
          "decimal_digits": 0,
          "rounding": 0,
          "code": "AWG",
          "country_code": "ARUBA",
          "date_format": "DD/MM/YYYY",
          "name_plural": "Aruban Florin",
          "currency_format": "1 234.56",
          "flag_img": "Aruba.png",
          "timezones": [
            {
              "name": "America/Aruba",
              "value": "America/Aruba"
            }
          ]
        }
      ]
    },
    {
      "temperatures": [
        {
          "name": "Celsius",
          "value": "Celsius"
        },
        {
          "name": "Fahrenheit",
          "value": "Fahrenheit"
        },
        {
          "name": "Kelvin",
          "value": "Kelvin"
        }
      ]
    },
    {
      "dateformats": [
        {
          "name": "DD/MM/YYYY",
          "value": "DD/MM/YYYY"
        },
        {
          "name": "MM/DD/YYYY",
          "value": "MM/DD/YYYY"
        }
      ]
    }
  ]
  
  export {dropdownData};