import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../services/authentication.service';
import { SharedService } from '../../shared.service';
import { ContentfulService } from '../../../../services/contentful.service';

@Component({
  selector: 'app-registration-success',
  templateUrl: './registration-success.component.html',
  styleUrl: './registration-success.component.less'
})
export class RegistrationSuccessComponent implements OnInit {

  backgroundImg = 'assets/images/img/registration_success_background.png';
  currentUser: any;
  locale = 'en-US';
  sponsorOnboardingCMSData: any;
  postOnboardingPopup: any
  popupTitle: any;
  popupDesc: any;
  popupCloseCTA: any;
  sponsorDocuments: any;

  constructor(
    public dialogRef: MatDialogRef<RegistrationSuccessComponent>,
    private router: Router,
    private authenticationService: AuthenticationService,
    private sharedService: SharedService,
    private contentfulService: ContentfulService,
    @Inject(MAT_DIALOG_DATA) data: any,
  ) {
    this.postOnboardingPopup = data;
    this.sponsorDocuments = JSON.parse(localStorage.getItem('sponsorDocuments') || '{}');
  }

  ngOnInit(): void {
    this.getLandingCMSData();
    this.authenticationService.currentUser.subscribe(data => {
      this.currentUser = data;
    });
    console.log('this.popupType',this.postOnboardingPopup);
  }

  submitDetails() {
    if(this.currentUser && this.currentUser.token) {
      this.authenticationService.logoutUser();
    } else {
      this.router.navigate(['/login']);
    }
    this.dialogRef.close();
  }

  getLandingCMSData() {
    this.sharedService.toggleGlobalLoader(true);
    this.contentfulService.getContent("35bdbvzhHRi3guRfwrXFmi", this.locale).subscribe(
      (data:any) => {
        this.sponsorOnboardingCMSData = data;
        console.log('this.sponsorOnboardingCMSData',this.sponsorOnboardingCMSData);
        if(this.postOnboardingPopup) {
          this.backgroundImg = 'https:' + this.sponsorOnboardingCMSData.postOnboardingSuccessBackground.fields.file.url;
          this.popupTitle = this.sponsorOnboardingCMSData.postOnboardingSuccessLabel;
          this.popupDesc = this.sponsorOnboardingCMSData.postOnboardingSuccessDesc;
          this.popupCloseCTA = this.sponsorOnboardingCMSData.postOnboardingSuccessCloseCta;
        } else if(this.sponsorDocuments && this.sponsorDocuments.documents && this.sponsorDocuments.documents.length != 0) {
          this.backgroundImg = 'https:' + this.sponsorOnboardingCMSData.loginAfterOnboardingSuccessBackground.fields.file.url;
          this.popupTitle = this.sponsorOnboardingCMSData.loginAfterOnboardingSuccessLabel;
          this.popupDesc = this.sponsorOnboardingCMSData.loginAfterOnboardingSuccessDesc;
          this.popupCloseCTA = this.sponsorOnboardingCMSData.loginAfterOnboardingSuccessCloseCta;
        } else {
          this.backgroundImg = 'https:' + this.sponsorOnboardingCMSData.onboardingSuccessBackground.fields.file.url;
          this.popupTitle = this.sponsorOnboardingCMSData?.onboardingSuccessLabel;
          this.popupDesc = this.sponsorOnboardingCMSData.onboardingSuccessDesc;
          this.popupCloseCTA = this.sponsorOnboardingCMSData.onboardingSuccessCloseCta;
        }
        this.sharedService.toggleGlobalLoader(false);
      }
    );
  }

}
