import { Injectable } from '@angular/core'
import { AlertDialogPayload } from '../interfaces/alert-dialog-payload.interface'
import { MatDialog } from '@angular/material/dialog'
import { AlertDialogComponent } from '../components/alert-dialog/alert-dialog.component'
import { DatePickerPayload } from '../interfaces/date-picker-payload.interface'
import { DatePickerComponent } from '../components/date-picker/date-picker.component'
import { DateRangePicker } from '../interfaces/date-range-picker.interface'
import { DateRangePickerComponent } from '../components/date-range-picker/date-range-picker.component'

@Injectable({
  providedIn: 'root'
})
export class LibraryService {
  constructor (private matDialog: MatDialog) {}

  openAlertDialog (payload: AlertDialogPayload) {
    payload.hideBackdrop = payload.hideBackdrop ?? false
    return this.matDialog.open(AlertDialogComponent, {
      backdropClass: ['mat-dialog-backdrop'],
      panelClass: ['hidable-panel'],
      width: '486px',
      data: payload,
      hasBackdrop: !payload.hideBackdrop,
      disableClose: true,
      ariaLabel: 'Alert Model',
      id: payload.id
    })
  }

  openDatePicker(payload: DatePickerPayload) {
    const backdropClasses = ['datepicker-dialog-backdrop', 'hidable-backdrop'];
    if (payload.hideBackdrop) {
      backdropClasses.push('datepicker-dialog-backdrop--hide');
    }
    return this.matDialog.open(DatePickerComponent, {
      backdropClass: backdropClasses,
      panelClass: ['datepicker-panel', 'hidable-panel'],
      width: '500px',
      data: payload,
      hasBackdrop: true,
      disableClose: true,
      ariaLabel: 'Date Picker',
    });
  }

  openDateRangePicker(payload: DateRangePicker) {
    const backdropClasses = ['datepicker-dialog-backdrop','hidable-backdrop'];
    if (payload.hideBackdrop) {
      backdropClasses.push('datepicker-dialog-backdrop--hide');
    }
    const panelClasses = ['datepicker-panel', 'hidable-panel'];
    if (payload.positionChange) {
      panelClasses.push('datepicker-panel--position-change');
    }
    return this.matDialog.open(DateRangePickerComponent, {
      backdropClass: backdropClasses,
      panelClass: panelClasses,
      width: '598px',
      data: payload,
      hasBackdrop: true,
      disableClose: true,
      ariaLabel: 'Date Range Picker',
    });
  }
}
