<div class="super-filter-button">
  <app-button
    [width]="66"
    [height]="42"
    [showIcon]="true"
    [imageUrl]="'/assets/images/icons/super-filter.png'"
    [borderColor]="'rgba(201,211,225,0.65)'"
    [showBorder]="true"
    appSuperFilter
    [(config)]="superFilterConfig"
  ></app-button>
  <button
    class="super-filter-button--reset"
    title="Reset Super Filter"
    (click)="resetFilter($event)"
    *ngIf="isFilterApplied"
  ></button>
</div>
