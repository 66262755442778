import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { User } from '../app/shared/models/users';
import { environment } from '../environments/environment';
import { SharedService } from '../app/shared/shared.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  protected currentUserSubject: BehaviorSubject<User>;
  public currentUser: BehaviorSubject<User>;
  usersInitial:string='';
  constructor(
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
    private sharedService: SharedService,
    private dialog : MatDialog
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser') || '{}')
    );
    this.currentUser = this.currentUserSubject;
  }

  private host = environment.host();

  public get currentUserValue(): User {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser') || '{}')
    );
    return this.currentUserSubject.value;
  }

  login(request: any) {
    // this.currentUserSubject.subscribe();
    return this.http.post<any>(this.host + 'login/', request).pipe(
      map((user) => {
        // login successful if there's a jwt token in the response
        // console.log('iniside login',user);
        if (user && user.token) {
          if (user.role.code == 'SPONSOR_REPRESENTATIVE') {
            console.log('iniside login--1',user);
            user.login_time = new Date().getTime();
            user.tokenExpireTime = user.login_time + (Number(user.expires_in) - 600) * 1000;
            user.header = true;
            localStorage.setItem('currentUser', JSON.stringify(user));
            localStorage.setItem('for_2fa_only', user['Two-FA-Token']);
            this.currentUserSubject.next(user);
            this.currentUser.next(user);
            // this.getUserInitial();
          } else {
            this.logoutUser();
            console.log('iniside login--2',user);
          }
        } else if (user && user['Two-FA-Token']) {
          user.login_time = new Date().getTime();
          user.tokenExpireTime = user.login_time + (Number(user.expires_in) - 600) * 1000;
          user.header = true;
          localStorage.setItem('currentUser', JSON.stringify(user));
          localStorage.setItem('for_2fa_only', user['Two-FA-Token']);
          this.currentUserSubject.next(user);
          this.currentUser.next(user);
          // this.getUserInitial();
        }


        return user;
      })
    );
  }
  getUserInitial(){
    if((this.currentUser.value.user.first_name && this.currentUser.value.user.first_name!=='') || (this.currentUser.value.user.last_name && this.currentUser.value.user.last_name!=='')){
      this.usersInitial = 
      (this.currentUser.value.user.first_name?.charAt(0).toUpperCase() || '') +
      (this.currentUser.value.user.last_name?.charAt(0).toUpperCase() || '');  
      localStorage.setItem('usersInitial',JSON.stringify(this.usersInitial))
    }
  }
  getProgramInfo() {
    this.http.get(`${environment.host()}programs/`).subscribe({
      next: (res: any) => {
        localStorage.setItem('programInfo', JSON.stringify(res));
        localStorage.setItem('program_date_format', JSON.stringify(res[0].date_format));
        this.sharedService.getCurrencySymbol();
      }
    });
  }
  logoutUser() {
    this.sharedService.toggleGlobalLoader(true);
    const requestUrl = environment.host() + 'logout/';
    this.http
      .post(requestUrl, {})
      .pipe(catchError((err) => of(err)))
      .subscribe({
        next: () => {
          this.clearCredentials();
          this.dialog.closeAll();
          this.sharedService.toggleGlobalLoader(false);
        },error : (error) => {
          this.sharedService.toggleGlobalLoader(false);
        }
      });
  }

  // logoutUser() {
  //   this.sharedService.toggleGlobalLoader(true);
  //   const requestUrl = environment.host() + 'logout/';
  //   this.http
  //     .post(requestUrl, {})
  //     .pipe(
  //       catchError((err) => {
  //         if (err.status === 401) {
  //           // Handle 401 error, e.g., clear credentials and navigate to login
  //           this.clearCredentials();
  //           this.router.navigate(['/login']); // Redirect to login
  //         }
  //         return of(err); // Return the error observable for further handling if needed
  //       }),
  //       finalize(() => {
  //         this.sharedService.toggleGlobalLoader(false); // Always hide loader
  //       })
  //     )
  //     .subscribe({
  //       next: () => {
  //         this.clearCredentials(); // Clear credentials on successful logout
  //         this.sharedService.toggleGlobalLoader(false);
  //         this.dialog.closeAll();
  //       },
  //       error: (error) => {
  //         // Optionally handle other errors if needed
  //         this.sharedService.toggleGlobalLoader(false);
  //       }
  //     });
  // }

  clearCredentials() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    var emptyuser: any = {};
    localStorage.clear();
    this.currentUserSubject.next(emptyuser);
    this.currentUser.next(emptyuser);
    // this.router.navigate(['/login']);
    location.reload();
  }

  updateCurrentUserObs(user: any) {
    localStorage.setItem('currentUser', JSON.stringify(user));
    this.currentUser.next(user);
    this.currentUserSubject.next(user);
  }

  getCurrentUserObs() {
    return this.currentUserSubject;
  }

  getTenantDetail() {
    return this.http.get(this.host + 'tenants/basic-info/');
  }
}
