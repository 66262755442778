<div class="super-filter">
  <div class="super-filter_modal">
    <div class="super-filter_modal__header">
      <div class="super-filter_modal__header--title">{{getGenericLabels?.SortAndFilter}}</div>
      <img
        src="/assets/images/icons/reset_filter.png"
        alt="reset filter"
        (click)="resetFilter()"
      />
    </div>
    <div class="super-filter_modal__body">
      <div class="super-filter_modal__body_sortBy" *ngIf="config.sort">{{getGenericLabels?.SortBy}}</div>
      <app-custom-select-dropdown
        [width]="300"
        [height]="48"
        [placeholder]="config.sort?.placeholder ?? 'Select'"
        [value]="config.sort?.value ?? config.sort?.default"
        (valueChange)="sortFilter(config, $event)"
        *ngIf="config.sort?.type == FilterType.SELECT"
        [options]="config.sort?.options!"
      ></app-custom-select-dropdown>

      <div class="super-filter_modal__body_filterData">
        <div class="super-filter_modal__body_sortBy">{{getGenericLabels?.FilterData}}</div>
        <div class="super-filter_modal__body_filterData--filters">
          <div *ngFor="let filter of config.filters">
            <app-multi-select-dropdown
              *ngIf="filter.type == FilterType.MULTI_SELECT"
              [fieldTitle]="filter.displayName"
              [width]="300"
              [height]="46"
              [options]="filter.options!"
              [value]="filter.value ?? cloneData(filter.default)"
              (valueChange)="filter.value = cloneData($event)"
              [allText]="filter.multiSelectConfig?.allText ?? 'All'"
              [showAll]="filter.multiSelectConfig?.showAll ?? false"
              [placeholder]="filter.placeholder ?? 'Select'"
            ></app-multi-select-dropdown>
            <app-custom-select-dropdown
              *ngIf="filter.type == FilterType.SELECT"
              [fieldTitle]="filter.displayName"
              [width]="300"
              [height]="46"
              [options]="filter.options!"
              [value]="filter.value ?? cloneData(filter.default)"
              (valueChange)="filter.value = cloneData($event)"
              [placeholder]="filter.placeholder ?? 'Select'"
            ></app-custom-select-dropdown>
            <app-date-input-field
            [fieldTitle]="filter.displayName"
            *ngIf="filter.type == FilterType.DATE"
            [config]="filter.dateConfig"
            [(value)]="filter.value"
            [defaultValue]="filter.default"
            [showError]="false"
            [placeholder]="filter.placeholder ?? 'Select Date'"
          ></app-date-input-field>
            <app-date-input-field
            [fieldTitle]="filter.displayName"
              *ngIf="filter.type == FilterType.DATE_RANGE"
              [config]="filter.dateConfig"
              [isRangePicker]="true"
              [(value)]="filter.value"
              [defaultValue]="filter.default"
              [showError]="false"
              [placeholder]="filter.placeholder ?? 'Select Date Range'"
            ></app-date-input-field>
          </div>
        </div>
      </div>
    </div>
    <div class="super-filter_modal__footer">
      <app-button
        [displayText]="getGenericLabels?.Apply"
        type="secondary"
        [height]="48"
        [width]="300"
        (clicked)="applySuperFilter()"
      ></app-button>
      <app-button
        [displayText]="getGenericLabels?.Cancel"
        type="default"
        [showBorder]="false"
        [height]="48"
        [width]="300"
        (clicked)="closeSuperFilter()"
      ></app-button>
    </div>
  </div>
</div>
