import { Component, OnInit, Renderer2 } from '@angular/core';
import { environment } from '../environments/environment';
import { CommonService } from '../services/common.service';
import { AuthenticationService } from '../services/authentication.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ContentfulService } from '../services/contentful.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.less'
})
export class AppComponent implements OnInit {

  users: any;
  title = 'merchant-portal-ui';
  themeClass: any;
  tenantInfo: any;
  currentUser: any;
  profileImage : any = '';
  showSideStepper: boolean = false;
  activeStep : string = '';
  private sideStepperRoutes: string[] = ['/offers', '/locations', '/profile','/payments', '/transactions']; // Define the routes where the side-stepper should be visible
  showHeaderFooter = true;
  showHeader = true;
  showFooter = true;
  locale = 'en-US';
  headerFooterCMSData: any;
  portalGenericSettings: any;
  
  private className = environment.themeVariable;
  constructor(
    private renderer: Renderer2,
    private authenticationService: AuthenticationService,
    private router: Router,
    private contentfulService: ContentfulService
  ) {
    // this.router.events.subscribe(() => {
    //   this.checkIfSideStepperShouldBeVisible();
    // });
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {

        if (event.url === '/home') {
          this.showHeader = false;
          this.showFooter = false;
        } else if(event.url == '/login' || event.url == '/login/forgetpassword'){
          this.showHeader = true;
          this.showFooter = true;
        } else if(event.url == '/home/becomepartner') {
          this.showHeader = false;
          this.showFooter = true;
        } else {
          this.showHeader = true;
          this.showFooter = true;
        }
        this.activeStep = event.url.split('/')[1];
        this.checkIfSideStepperShouldBeVisible(event.url);
      });
   }

  ngOnInit() {
    // Apply the class when component initializes
    this.renderer.addClass(document.body, this.className);
    this.authenticationService.currentUser.subscribe(data => {
      this.currentUser = data;
    });
    this.initialize();
    this.getHeaderFooterLabelCMSData();
    this.getGenericSettings();
    this.contentfulService.callEntriesAPI();
    const currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    this.profileImage = currentUser?.profile_image;
  }


  private checkIfSideStepperShouldBeVisible(url: string): void {
    // If the URL is exactly '/offers', show the side stepper
    this.showSideStepper = false;

    // Iterate over each route in the sideStepperRoutes array
    for (const route of this.sideStepperRoutes) {
      if (url === route) {
        // Show side stepper if the URL matches exactly one of the routes
        this.showSideStepper = true;
        return;
      } else if (url.startsWith(route) && (url === route || url.includes('/new') || /\/\d+/.test(url))) {
        // Hide side stepper if the URL starts with one of the routes but includes '/new' or any other subroute like /offers/:id
        this.showSideStepper = false;
        return;
      }
    }
  }

  initialize() {
    this.authenticationService.getTenantDetail().subscribe((data: any) => {
      localStorage.setItem('tenantInfo',JSON.stringify(data));
      this.tenantInfo = data;
    });
  }

  getHeaderFooterLabelCMSData() {
    this.contentfulService.getContent("657FKyg3tI6y8E4KEMlp9x", this.locale).subscribe(
      (data:any) => {
        this.headerFooterCMSData = data;
      }
    );
  }

  getGenericSettings() {
    this.contentfulService.getContent("5ASD06TLkRQJsyD0Wkx1qB", this.locale).subscribe(
      (data:any) => {
        this.portalGenericSettings = data;
        localStorage.setItem('portalGenericSettings', JSON.stringify(this.portalGenericSettings));
      }
    );
  }

}