import { Component, HostListener, Input, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../../services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.less'
})
export class HeaderComponent implements OnInit {

  @Input() headerFooterCMSData: any;
  currentUser: any;
  isLogin: boolean = false;
  sponsorLogo: any;
  notificationIcon: any;
  settingsIcon: any;
  getGenericSettings: any;
  showTooltip: boolean = false;
  logoutIcon='assets/images/icons/logout_icon.png';
  constructor(
    private authenticationService: AuthenticationService, 
    private router:Router,
  ) {
  }
  ngOnInit(): void {
    this.getGenericSettings = JSON.parse(localStorage.getItem('portalGenericSettings') || '{}');
    this.sponsorLogo = 'https:' +  this.headerFooterCMSData?.sponsorLogo?.fields.file.url;
    this.notificationIcon = 'https:' +  this.headerFooterCMSData?.notificationIcon?.fields.file.url;
    this.settingsIcon = 'https:' +  this.headerFooterCMSData?.settingsIcon?.fields.file.url;
    this.authenticationService.currentUser.subscribe(data => {
      this.currentUser = data;
      if(this.currentUser && (this.currentUser.token)) {
        this.isLogin = true;
      } else {
        this.isLogin = false;
      }
    });
  }
  get usersInitial() {
    const value = localStorage.getItem('usersInitial');
    return value !== null ? JSON.parse(value) : '';
  } 
  logout() {
    this.authenticationService.logoutUser();
  }
  toggleTooltip(){
  this.showTooltip=!this.showTooltip;
  }
  routeToLogin() {
    if(this.isLogin) {
      this.router.navigate(['/offers']);
    } else {
      this.router.navigate(['/home']);
    }
  }

}
