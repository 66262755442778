import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FileUploadService } from '../../services/file-upload.service';
import { SharedService } from '../../../../shared/shared.service';
import { environment } from '../../../../../environments/environment';
@Component({
  selector: 'app-upload-image-section',
  templateUrl: './upload-image-section.component.html',
  styleUrl: './upload-image-section.component.less'
})
export class UploadImageSectionComponent {

  private host = environment.host();
  @Input() description: string = ''; // Optional description
  @Input() showDescription : boolean = true;
  @Input()  width : number = 100;
  @Input() widthUnit: 'px' | '%' | 'rem' | 'em' = 'px';
  @Input()  height : number = 100;
  @Input() heightUnit: 'px' | '%' | 'rem' | 'em' = 'px';

  @Input() uploadTitle: string = 'Upload File';
  @Output() uploadImage = new EventEmitter();
  @Output() emitErrorMessage = new EventEmitter();
  @Input() fileNameText: string = 'No files selected';
  @Input() subfolder: string = 'program/logos/';
  @Input() imageWidth: string = '80px';
  @Input() imageHeight: string = '80px';
  @Input() imageRadius: string = '50%';
  @Input() helpText?: string;
  @Input() fieldTitle?: string;
  @Input() required: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() showbackdrop: boolean = false;
  @Input() isBanner: boolean = false;
  @Input() margin: string = '0%';
  @Input() isObjectFitCover : boolean = false;
  @Input() deviceTypeName?:string;
  @Input() uplodedImageUrl?:string;
  @Input() rounded:boolean=false;
  @Input() backgroundColor:string= '#FFFFFF';
  fileDetails: any = {};
  error: boolean = false;
  errorMessage: string = '';
  fieldId=Date.now();
  constructor(
    private fileUploadService: FileUploadService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {}

  onClick(event: Event) {
    const targetElement = event.target as HTMLInputElement;
    this.sharedService.toggleGlobalLoader(true);
    if (targetElement.files?.length) {
      this.fileNameText = targetElement.files[0].name;
      this.fileUploadService.uploadFile(targetElement).subscribe({
        next: (file: any) => {
          this.fileDetails = file;
          const request = {
            subfolder: this.subfolder,
            file_name: file.name,
            file_type: file.type,
          };
          this.upLoadImage(request);
        },error : (reason) => {
          this.sharedService.toggleGlobalLoader(false);
        }
      });
    }else{
      this.sharedService.toggleGlobalLoader(false);
    }
  }
  upLoadImage(request: any) {
    this.fileUploadService.upLoadImage(request, this.host).subscribe({
      next: (data: any) => {
        this.postImage(data, request);
      }, error : (reason) => {
        this.emitErrorMessage.emit(reason.error);
        this.sharedService.toggleGlobalLoader(false);
      }
    });
  }

  postImage(val: any, request: any) {
    let xhr = new XMLHttpRequest();
    xhr.open('PUT', val.signed_request);
    xhr.setRequestHeader('Content-Type', request.file_type);
    xhr.onload = () => {
      if (xhr.status === 200) {
        this.uplodedImageUrl=val.url;
        this.uploadImage.emit(val.url);
        setTimeout(() : void => {
          this.sharedService.toggleGlobalLoader(false);
        }, 3000); 
      }
    };
    xhr.onerror = (reason: any) => {
      this.error = true;
      this.emitErrorMessage.emit(reason.error);
      this.errorMessage = reason.error.error[0].message;
      this.fileDetails.error = this.error;
      this.fileDetails.errorMessage = this.errorMessage;
      this.sharedService.toggleGlobalLoader(false);
    };
    let blob = this.dataURItoBlob(this.fileDetails.data);
    xhr.send(blob);
  }

  dataURItoBlob(dataURI?: any) {
    let byteString: any = atob(dataURI.split(',')[1]);
    let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
}
