import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { authGuard } from './shared/guards/auth.guard';
import { FaqComponent } from './shared/components/faq/faq.component';
import { TermsConditionsComponent } from './shared/components/terms-conditions/terms-conditions.component';
import { VersionComponent } from './shared/components/version/version.component';
import { InvoiceDetailsComponent } from './screens/payments/components/invoice-details/invoice-details.component';
const routes: Routes = [
  { path: 'offers', loadChildren: () => import('./screens/offers/offers.module').then(m => m.OffersModule), canActivate: [authGuard(true)] },
  { path: 'locations', loadChildren: () => import('./screens/locations/locations.module').then(m => m.LocationsModule), canActivate: [authGuard(true)] },
  { path: 'transactions', loadChildren: () => import('./screens/transactions/transactions.module').then(m => m.TransactionsModule), canActivate: [authGuard(true)] },
  { path: 'profile', loadChildren: () => import('./screens/profile/profile.module').then(m => m.ProfileModule), canActivate: [authGuard(true)] },
  { path: 'login', loadChildren: () => import('./screens/login/login.module').then(m => m.LoginModule),canActivate: [authGuard(false)] },
  { path: 'payments', loadChildren: () => import('./screens/payments/payments.module').then(m => m.PaymentsModule),canActivate: [authGuard(true)] },
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./screens/onboarding/onboarding.module').then(m => m.OnboardingModule),canActivate: [authGuard(false)] },
  { path: 'version', component: VersionComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'tnc', component: TermsConditionsComponent },
  { path: 'invoice/:id', component: InvoiceDetailsComponent },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
